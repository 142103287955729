import {
    jobtypeConstants
  } from '../_constants';
  
  export function jobtypes(state = {}, action) {
  
    switch (action.type) {
  
  
      case jobtypeConstants.GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.GETALL_SUCCESS:
        return {
          ...state,
          addJobTypeSuccess: false,
          items: action.jobtypes.getJobTypeList.list,
          total: action.jobtypes.getJobTypeList.total
        };
      case jobtypeConstants.GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobtypeConstants.GET_ALL_JOBTYPE_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.GET_ALL_JOBTYPE_SUCCESS:
        return {
          ...state,
          getAllJobType: action.jobtypes.getAllJobType
        };
      case jobtypeConstants.GET_ALL_JOBTYPE_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobtypeConstants.GETALL_JOBTYPE_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.GETALL_JOBTYPE_NOTIFY_SUCCESS:
        return {
          ...state,
          listOfNotification: action.jobtypes.listOfNotification.list,
          listOfNotificationtotal: action.jobtypes.listOfNotification.total
        };
      case jobtypeConstants.GETALL_JOBTYPE_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobtypeConstants.UPDATE_JOBTYPE_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.UPDATE_JOBTYPE_NOTIFY_SUCCESS:
        return {
          ...state,
        };
      case jobtypeConstants.UPDATE_JOBTYPE_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case jobtypeConstants.GET_RESTAURANT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.GET_RESTAURANT_SUCCESS:
        return {
          ...state,
          addMenuSuccess: false,
          addItemSuccess: false,
          updateitem: false,
          updateCategory: false,
          restaurantDetails: action.jobtypes.restaurantDetails
        };
      case jobtypeConstants.GET_RESTAURANT_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobtypeConstants.ADD_RESTAURANT_JOBTYPE_REQUEST:
        return {
          ...state
        };
      case jobtypeConstants.ADD_RESTAURANT_JOBTYPE_SUCCESS:
        return {
          ...state,
          addJobTypeSuccess: true
        };
      case jobtypeConstants.ADD_RESTAURANT_JOBTYPE_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case jobtypeConstants.ADD_MENU_REQUEST:
        return {
          ...state
        };
      case jobtypeConstants.ADD_MENU_SUCCESS:
        return {
          ...state,
          addMenuSuccess: true
        };
      case jobtypeConstants.ADD_MENU_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobtypeConstants.ADD_ITEM_REQUEST:
        return {
          ...state
        };
      case jobtypeConstants.ADD_ITEM_SUCCESS:
        return {
          ...state,
          addItemSuccess: true
        };
      case jobtypeConstants.ADD_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobtypeConstants.UPDATE_CATEGORY_REQUEST:
        return {
          ...state
        };
      case jobtypeConstants.UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          updateCategory: true
        };
      case jobtypeConstants.UPDATE_CATEGORY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobtypeConstants.UPDATE_ITEM_REQUEST:
        return {
          ...state
        };
      case jobtypeConstants.UPDATE_ITEM_SUCCESS:
        return {
          ...state,
          updateitem: true
        };
      case jobtypeConstants.UPDATE_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case jobtypeConstants.FILE_UPLOAD_STATUS_SUCCESS:
        return {
          ...state, filesDetails: action.uploadImage.filesDetails,
        };
  
      case jobtypeConstants.FILE_UPLOAD_STATUS_FAILURE:
        return {
          ...state
        };
  
  
      case jobtypeConstants.STATS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case jobtypeConstants.STATS_SUCCESS:
        return {
          ...state,
          statics: action.jobtypes.statics
        };
      case jobtypeConstants.STATS_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      default:
        return state
    }
  }