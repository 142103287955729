
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const jobService = {
    login,
    logout,
    addJob,
    uploadImage,
    getFilteredAssignedJob,
    disableJob,
    updateJob,
    deleteJob,
    getAllJob,
    getMaterialDetailsByJobId,
    updateMaterialDetailsByJobId,
    createInvoiceDetails
};
function logout() {
    localStorage.removeItem('job');
}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/adminlogin`, requestOptions)
        .then(handleResponse)
        .then(job => {
            let jobObj = {
                jobinfo: job.data
            }
            if (job.data) {
                localStorage.setItem('job', JSON.stringify(job.data));
            }

            return jobObj;
        });
}
function getFilteredAssignedJob(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getFilteredAssignedJob`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                getJobList: data.data
            }
            console.log("jobObjjobObjjobObj", jobObj);

            return jobObj;
        });
}


function getMaterialDetailsByJobId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/getMaterialDetailsByJobId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                getJobList: data.data
            }
            return jobObj;
        });
}



function updateMaterialDetailsByJobId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/updateMaterialDetailsByJobId`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                getJobList: data.data
            }
            return jobObj;
        });
}

function createInvoiceDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/createInvoiceDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                getJobList: data.data
            }
            return jobObj;
        });
}
function getAllJob() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL2 + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                getAllJob: data.data
            }
            console.log();

            return jobObj;
        });
}
function addJob(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/createJob`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobObj = {
                addJob: data.data
            }
            console.log();

            return jobObj;
        });
}
function updateJob(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/updateJob`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobObj = {
                addJob: data.data
            }
            return jobObj;
        });
}
function disableJob(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/changeState`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobObj = {
                addJob: data.data
            }
            console.log();

            return jobObj;
        });
}
function deleteJob(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL2 + `/deleteJob`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobObj = {
                addJob: data.data
            }
            console.log();

            return jobObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL2 + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let jobObj = {
                filesDetails: res.data
            }
            return jobObj;
        });
}
function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}