
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const designationService = {
    login,
    logout,
    addDesignation,
    uploadImage,
    getDesignationList,
    disableDesignation,
    updateDesignation,
    deleteDesignation,
    getAllDesignation,
};
function logout() {
    localStorage.removeItem('designation');
}
function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
            .then(handleResponse)
            .then(designation => {
                let designationObj = {
                    designationinfo: designation.data
                }
                if (designation.data) {
                    localStorage.setItem('designation', JSON.stringify(designation.data));
                }
                
                return designationObj;
            });
}
function getDesignationList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getDesignationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let designationObj = {
                getDesignationList: data.data
            }
            console.log("designationObjdesignationObjdesignationObj",designationObj);
            
            return designationObj;
        });
}
function getAllDesignation() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let designationObj = {
                getAllDesignation: data.data
            }
            console.log();
            
            return designationObj;
        });
}
function addDesignation(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createDesignation`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let designationObj = {
                addDesignation: data.data
            }
            console.log();
            
            return designationObj;
        });
}
function updateDesignation(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateDesignation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let designationObj = {
                addDesignation: data.data
            }
            return designationObj;
        });
}
function disableDesignation(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let designationObj = {
                addDesignation: data.data
            }
            console.log();
            
            return designationObj;
        });
}
function deleteDesignation(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteDesignation`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let designationObj = {
                addDesignation: data.data
            }
            console.log();
            
            return designationObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let designationObj = {
                filesDetails: res.data
            }
            return designationObj;
        });
}
function handleResponse(response) {
   // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}