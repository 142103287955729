import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { dashboard } from "./dashboard.reducer";
import { workers } from "./worker.reducer";
import { jobs } from "./job.reducer";
import { landlords } from "./landlord.reducer";
import { contractors } from "./contractor.reducer";
import { internalstaff } from "./internalstaff.reducer";
import { jobtypes } from "./jobtype.reducer";
import { designation } from "./designation.reducer";
import { joblandlords } from "./joblandlord.reducer";
import { locationlandlords } from "./locationlandlord.reducer";
import { archives } from "./Archive.reducer";
import { loader } from "./loader.reducer";
import { contractorsLandlord } from "./contractorLandlord.reducer";

const rootReducer = combineReducers({
  authentication,
  contractors,
  landlords,
  jobs,
  workers,
  users,
  alert,
  dashboard,
  internalstaff,
  jobtypes,
  designation,
  joblandlords,
  archives,
  loader,
  locationlandlords,
  contractorsLandlord,
});

export default rootReducer;
