import {
  contractorConstants
} from '../_constants';

export function contractors(state = {}, action) {

  switch (action.type) {


    case contractorConstants.ASSIGN_LANDLORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.ASSIGN_LANDLORD_SUCCESS:
      return {
        ...state,
        addContractorSuccess: true,
        // getLandlordListItems: action.contractors.getLandlordList.list,
        // getLandlordListTotal: action.contractors.getLandlordList.total
      };
    case contractorConstants.ASSIGN_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.GETALL_LANDLORD_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.GETALL_LANDLORD_LIST_SUCCESS:
      return {
        ...state,
        addContractorSuccess: false,
        getLandlordListItems: action.contractors.getLandlordList.list,
        getLandlordListTotal: action.contractors.getLandlordList.total
      };
    case contractorConstants.GETALL_LANDLORD_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.GETALL_CONTRACTOR_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.GETALL_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        addContractorSuccess: false,
        items: action.contractors.getContractorList.list,
        total: action.contractors.getContractorList.total
      };
    case contractorConstants.GETALL_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.GET_ALL_CONTRACTOR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.GET_ALL_CONTRACTOR_SUCCESS:
      return {
        ...state,
        getAllContractor: action.contractors.getAllContractor
      };
    case contractorConstants.GET_ALL_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.UPDATE_CONTRACTOR_PASSWORD_REQUEST:
      return {
        ...state
      };
    case contractorConstants.UPDATE_CONTRACTOR_PASSWORD_SUCCESS:
      return {
        ...state,
        addContractorSuccess: true
      };
    case contractorConstants.UPDATE_CONTRACTOR_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.GETALL_CONTRACTOR_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.GETALL_CONTRACTOR_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.contractors.listOfNotification.list,
        listOfNotificationtotal: action.contractors.listOfNotification.total
      };
    case contractorConstants.GETALL_CONTRACTOR_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.UPDATE_CONTRACTOR_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.UPDATE_CONTRACTOR_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case contractorConstants.UPDATE_CONTRACTOR_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case contractorConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.contractors.restaurantDetails
      };
    case contractorConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case contractorConstants.ADD_RESTAURANT_CONTRACTOR_REQUEST:
      return {
        ...state
      };
    case contractorConstants.ADD_RESTAURANT_CONTRACTOR_SUCCESS:
      return {
        ...state,
        addContractorSuccess: true
      };
    case contractorConstants.ADD_RESTAURANT_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case contractorConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case contractorConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case contractorConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case contractorConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case contractorConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case contractorConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case contractorConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case contractorConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case contractorConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case contractorConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case contractorConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case contractorConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case contractorConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case contractorConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case contractorConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case contractorConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.contractors.statics
      };
    case contractorConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}