export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    OTP_FORGOT_PASSWORD_REQUEST: 'OTP_FORGOT_PASSWORD_REQUEST',
    OTP_FORGOT_PASSWORD_SUCCESS: 'OTP_FORGOT_PASSWORD_SUCCESS',
    OTP_FORGOT_PASSWORD_FAILURE: 'OTP_FORGOT_PASSWORD_FAILURE',

    LOGIN_LANDLORD_REQUEST: 'USERS_LOGIN_LANDLORD_REQUEST',
    LOGIN_LANDLORD_SUCCESS: 'USERS_LOGIN_LANDLORD_SUCCESS',
    LOGIN_LANDLORD_FAILURE: 'USERS_LOGIN_LANDLORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    STATS1111_REQUEST: 'USERS_STATS1111_REQUEST',
    STATS1111_SUCCESS: 'USERS_STATS1111_SUCCESS',
    STATS1111_FAILURE: 'USERS_STATS1111_FAILURE',

    GET_ALL_USER_REQUEST: 'USERS_GET_ALL_USER_REQUEST',
    GET_ALL_USER_SUCCESS: 'USERS_GET_ALL_USER_SUCCESS',
    GET_ALL_USER_FAILURE: 'USERS_GET_ALL_USER_FAILURE',

    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',


    GET_DASHBOARD_DATA_REQUEST: "GET_DASHBOARD_DATA_REQUEST",
    GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
    GET_DASHBOARD_DATA_FAILURE: "GET_DASHBOARD_DATA_FAILURE",

};
