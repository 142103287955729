import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Switch,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Button } from "../../../../components/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  sortArrow: {
    width: "15.2px",
    position: "absolute",
    marginLeft: "5px",
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    updatehandleOpenCreateModal,
    updatePwdhandleOpenCreateModal,
    handleOpenViewModal,
    handleSort,
    sortOrder,
    sortBy,
    classes,
    onDisable,
  } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("company_name")}
              >
                Company Name
                {sortBy === "company_name" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "company_name" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>

              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("contact_name")}
              >
                Contact Name
                {sortBy === "contact_name" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "contact_name" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>

              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("email")}
              >
                Email
                {sortBy === "email" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "email" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      color: "#4a4a4a",
                      "&:hover": {
                        background: "#efefef",
                        textAlign: "left",
                      },
                    }}
                    title="More Details"
                    onClick={() => handleOpenViewModal(tempdata)}
                  >
                    {tempdata.company_name ? tempdata.company_name : "-"}
                  </Button>
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.contact_name ? tempdata.contact_name : "-"}
                </TableCell>
                <TableCell>{tempdata.email}</TableCell>
                <TableCell>
                  <Switch
                    checked={tempdata.active}
                    onChange={() => onDisable(tempdata)}
                    color="primary"
                    name="status"
                    id={`landlord_table${offset + index + 1}`}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => updatehandleOpenCreateModal(tempdata)}
                  >
                    update
                  </Button>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => updatePwdhandleOpenCreateModal(tempdata)}
                  >
                    update password
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
