import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Switch,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Button } from "../../../../components/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  sortArrow: {
    width: "15.2px",
    position: "absolute",
    marginLeft: "5px",
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    updatehandleOpenCreateModal,
    landlordAssignModal,
    updatePwdhandleOpenCreateModal,
    handleSort,
    sortOrder,
    sortBy,
    classes,
    onDisable,
  } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell>S. No.</TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("company_name")}
              >
                Company Name
                {sortBy === "company_name" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "company_name" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("contact_name")}
              >
                Contact Name
                {sortBy === "last_name" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "last_name" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("email")}
              >
                Email
                {sortBy === "email" && sortOrder === "-1" ? (
                  <ArrowUpwardIcon className={classes.sortArrow} />
                ) : sortBy === "email" && sortOrder === "1" ? (
                  <ArrowDownwardIcon className={classes.sortArrow} />
                ) : null}
              </TableCell>
              <TableCell>Assigned Landlord</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assign</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.company_name ? tempdata.company_name : tempdata.first_name ? tempdata.first_name : "-"}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.contact_name ? tempdata.contact_name : "-"}
                </TableCell>
                <TableCell>{tempdata.email}</TableCell>
                <TableCell>
                  {/* {JSON.stringify(tempdata.landlordList)} */}
                  {
                    tempdata && tempdata.landlordList && tempdata.landlordList.length > 0 ?
                      tempdata.landlordList.map((element, index) => (

                        element.company_name ? element.company_name + " , " : "-"

                      ))
                      : "N/A"
                  }
                </TableCell>
                <TableCell>
                  <Switch
                    checked={tempdata.active}
                    onChange={() => onDisable(tempdata)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => landlordAssignModal(tempdata)}
                  >
                    Assign Landlord
                  </Button>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>

                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    style={{ marginRight: "5px" }}
                    onClick={() => updatehandleOpenCreateModal(tempdata)}
                  >
                    update
                  </Button>
                  <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    onClick={() => updatePwdhandleOpenCreateModal(tempdata)}
                  >
                    update password
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
