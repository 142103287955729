import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const workerService = {
  login,
  logout,
  addWorker,
  uploadImage,
  updatePassword,
  getWorkerList,
  disableWorker,
  updateWorker,
  deleteWorker,
  getAllWorker,
  getPackageList,
  getCurrentUserPackageByUserId,
  buyPackage,
  getMostRecentUserPackByUserId,
  getUserPackageListByUser,
};
function logout() {
  localStorage.removeItem("worker");
}
function login(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
    .then(handleResponse)
    .then((worker) => {
      let workerObj = {
        workerinfo: worker.data,
      };
      if (worker.data) {
        localStorage.setItem("worker", JSON.stringify(worker.data));
      }

      return workerObj;
    });
}

function updatePassword(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/updatePassword`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let userObj = {
        updatePassword: data.data,
      };
      console.log("I am in service", userObj);

      return userObj;
    });
}

function getWorkerList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getWorkerList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getWorkerList: data.data,
      };
      console.log();

      return workerObj;
    });
}

function getPackageList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getPackageList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getPackageList: data.data,
      };
      return workerObj;
    });
}

function getMostRecentUserPackByUserId(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getMostRecentUserPackByUserId`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getMostRecentUserPackByUserId: data.data,
      };
      return workerObj;
    });
}
function getUserPackageListByUser(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getUserPackageListByUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getUserPackageListByUser: data.data,
      };
      return workerObj;
    });
}
function getCurrentUserPackageByUserId() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    // body: JSON.stringify(),
  };
  return fetch(CONST.BACKEND_URL2 + `/getCurrentUserPackageByUserId`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getCurrentUserPackageByUserId: data.data,
      };
      return workerObj;
    });
}
function getAllWorker() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
  };
  return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        getAllWorker: data.data,
      };
      console.log();

      return workerObj;
    });
}
function addWorker(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/createNewUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        addWorker: data.data,
      };
      console.log();

      return workerObj;
    });
}
function buyPackage(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/buyPackage`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        buyPackage: data.data,
      };
      console.log();

      return workerObj;
    });
}
function updateWorker(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/updateUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        addWorker: data.data,
      };
      return workerObj;
    });
}
function disableWorker(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/changeStateLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        addWorker: data.data,
      };
      return workerObj;
    });
}
function deleteWorker(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/deleteWorker`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let workerObj = {
        addWorker: data.data,
      };
      console.log();

      return workerObj;
    });
}
function uploadImage(filedata) {
  let header = new Headers({
    Authorization: authHeader().Authorization,
  });
  var data = new FormData();
  data.append("image", filedata);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: data,
  };
  return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      let workerObj = {
        filesDetails: res.data,
      };
      return workerObj;
    });
}
function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
