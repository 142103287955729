
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const jobtypeService = {
    login,
    logout,
    addJobType,
    uploadImage,
    getJobTypeList,
    disableJobType,
    updateJobType,
    deleteJobType,
    getAllJobType,
};
function logout() {
    localStorage.removeItem('jobtype');
}
function login(data) {

    let header = new Headers({
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        });
        const requestOptions = {
            method: "POST",
            headers: header,
            body: JSON.stringify(data)
        }
        return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
            .then(handleResponse)
            .then(jobtype => {
                let jobtypeObj = {
                    jobtypeinfo: jobtype.data
                }
                if (jobtype.data) {
                    localStorage.setItem('jobtype', JSON.stringify(jobtype.data));
                }
                
                return jobtypeObj;
            });
}
function getJobTypeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getJobTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobtypeObj = {
                getJobTypeList: data.data
            }
            console.log("i am in service",jobtypeObj);
            
            return jobtypeObj;
        });
}
function getAllJobType() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobtypeObj = {
                getAllJobType: data.data
            }
            console.log();
            
            return jobtypeObj;
        });
}
function addJobType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createJobType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobtypeObj = {
                addJobType: data.data
            }
            console.log();
            
            return jobtypeObj;
        });
}
function updateJobType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateJobType`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let jobtypeObj = {
                addJobType: data.data
            }
            return jobtypeObj;
        });
}
function disableJobType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobtypeObj = {
                addJobType: data.data
            }
            console.log();
            
            return jobtypeObj;
        });
}
function deleteJobType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteJobType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let jobtypeObj = {
                addJobType: data.data
            }
            console.log();
            
            return jobtypeObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let jobtypeObj = {
                filesDetails: res.data
            }
            return jobtypeObj;
        });
}
function handleResponse(response) {
   // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}