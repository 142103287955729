import {
  staffConstants
} from '../_constants';

export function internalstaff(state = {}, action) {

  switch (action.type) {

    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_REQUEST:
      return {
        ...state
      };
    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_SUCCESS:
      return {
        ...state,
        addStaffSuccess: true,
        addContractorSuccess: true
      };
    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.UPDATE_STAFF_LANDLORD_REQUEST:
      return {
        ...state
      };
    case staffConstants.UPDATE_STAFF_LANDLORD_SUCCESS:
      return {
        ...state,
        addStaffSuccess: true
      };
    case staffConstants.UPDATE_STAFF_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.ADD_STAFF_LANDLORD_REQUEST:
      return {
        ...state
      };
    case staffConstants.ADD_STAFF_LANDLORD_SUCCESS:
      return {
        ...state,
        addStaffSuccess: true
      };
    case staffConstants.ADD_STAFF_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addStaffSuccess: false,
        items: action.staffs.getStaffList.list,
        total: action.staffs.getStaffList.total
      };
    case staffConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case staffConstants.GETALL_IS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_IS_SUCCESS:
      return {
        ...state,
        loading: false,
        addStaffSuccess: false,
        items: action.staffs.getIsList.list,
        total: action.staffs.getIsList.total
      };
    case staffConstants.GETALL_IS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_SUCCESS:
      return {
        ...state,
        addStaffSuccess: false,
        itemsLandlord: action.staffs.getInternalStaffLandLordList.list,
        totalLandlord: action.staffs.getInternalStaffLandLordList.total
      };
    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.GETALL_ITNL_STAFF_JOB_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_ITNL_STAFF_JOB_SUCCESS:
      return {
        ...state,
        addStaffSuccess: false,
        itemsJob: action.staffs.getInternalStaffByLandLord.list,
        total: action.staffs.getInternalStaffByLandLord.total
      };
    case staffConstants.GETALL_ITNL_STAFF_JOB_FAILURE:
      return {
        ...state,
        error: action.error
      };

    // case staffConstants.GET_CONTRACTOR_BY_LANDLORD_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case staffConstants.GET_CONTRACTOR_BY_LANDLORD_SUCCESS:
    //   return {
    //     ...state,
    //     addStaffSuccess: false,
    //     itemsJob: action.staffs.getInternalStaffByLandLord.list,
    //     total: action.staffs.getInternalStaffByLandLord.total
    //   };
    // case staffConstants.GET_CONTRACTOR_BY_LANDLORD_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_SUCCESS:
      return {
        ...state,
        addStaffSuccess: false,
        items: action.staffs.getInternalStaffByLandLord.list,
        total: action.staffs.getInternalStaffByLandLord.total
      };
    case staffConstants.GETALL_ITNL_STAFF_LANDLOARD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.GET_ALL_STAFF_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GET_ALL_STAFF_SUCCESS:
      return {
        ...state,
        getAllStaff: action.staffs.getAllStaff
      };
    case staffConstants.GET_ALL_STAFF_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.GETALL_STAFF_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GETALL_STAFF_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.staffs.listOfNotification.list,
        listOfNotificationtotal: action.staffs.listOfNotification.total
      };
    case staffConstants.GETALL_STAFF_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.UPDATE_STAFF_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.UPDATE_STAFF_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case staffConstants.UPDATE_STAFF_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_REQUEST:
      return {
        ...state
      };
    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_SUCCESS:
      return {
        ...state,
        addStaffSuccess: true,
        addContractorSuccess: true
      };
    case staffConstants.UPDATE_INTERNAL_STAFF_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case staffConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.staffs.restaurantDetails
      };
    case staffConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case staffConstants.ADD_RESTAURANT_STAFF_REQUEST:
      return {
        ...state
      };
    case staffConstants.ADD_RESTAURANT_STAFF_SUCCESS:
      return {
        ...state,
        addStaffSuccess: true
      };
    case staffConstants.ADD_RESTAURANT_STAFF_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case staffConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case staffConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case staffConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case staffConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case staffConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case staffConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case staffConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case staffConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case staffConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case staffConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case staffConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case staffConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case staffConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, filesDetails: action.uploadImage.filesDetails,
      };

    case staffConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


    case staffConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case staffConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.staffs.statics
      };
    case staffConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}