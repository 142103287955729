/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { jobActions } from '../../../../_actions';


function countTimeStampReduce(array) {
    if (!Array.isArray(array)) {
        return 0;
    }

    return array.reduce((acc, x) => acc + (x.offSiteTime - x.onSiteTime), 0);
}

function convertMilliseconds(milliseconds) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    const remainingMilliseconds = milliseconds % 1000;

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        milliseconds: remainingMilliseconds
    };
}


const Invoice = ({ jobMaterialDetails, viewRowData, propsData, setEditInvoice, handelCreateApi }) => {
    console.log('jobMaterialDetails________________jobMaterialDetails', jobMaterialDetails);
    console.log('viewRowData_____________________viewRowData', viewRowData);
    const newData1 = useMemo(() => jobMaterialDetails.MaterialUsed.reduce((acc, item) => acc.concat(item.materials), []), [jobMaterialDetails]);
    const newData2 = useMemo(() => jobMaterialDetails.MaterialUsed.reduce((acc, item) => acc.concat(item.equipment), []), [jobMaterialDetails]);
    const newData3 = useMemo(() => viewRowData?.assigned_worker_status?.reduce((acc, x) => acc + countTimeStampReduce(x.timeData), 0), [viewRowData])
    const [formData, setFormData] = useState(() => ({
        company_name: viewRowData.assign_to.company_name,
        job_address: `${viewRowData.location_id.linecomplete}, ${viewRowData.location_id.pin}, ${viewRowData.location_id.city}, ${viewRowData.location_id.state}`,
        taxNumber: `${jobMaterialDetails?.invoiceDetailsByJobId?.taxRegistrationNo}`,
        job_title: viewRowData.job_title,
        invoice_number: viewRowData.job_id.replace(/^(DM|PM) - /, ""),
        invoice_date: moment().format("YYYY-MM-DD"),
        ats_job_id: viewRowData.job_id,
        bill_to: `${viewRowData.created_by.company_name}`,
        address: viewRowData.created_by.address,
        phone: viewRowData.created_by.contact,
        materialDetails: newData1,
        equipmentDetails: newData2,
        totals: {
            subtotal: 0,
            salesTax: jobMaterialDetails?.invoiceDetailsByJobId?.salesTax ?? 0,
            total: 0,
            taxRate: jobMaterialDetails?.invoiceDetailsByJobId?.taxRate ?? 0,
            deposit: jobMaterialDetails?.invoiceDetailsByJobId?.depositReceived ?? 0
        },
        job_details: viewRowData.job_details,
        jobQtyHours: convertMilliseconds(newData3).hours,
        jobQtyMinutes: convertMilliseconds(newData3).minutes,
        jobQtySeconds: convertMilliseconds(newData3).seconds,
        jobRate: jobMaterialDetails?.invoiceDetailsByJobId?.jobRate ?? 0,
        jobPrice: 0,

    }));
    const totalTimeReturnData = () => {
        const hours = formData.jobQtyHours;
        const minutes = formData.jobQtyMinutes;
        const seconds = formData.jobQtySeconds;
        const totalHours = hours + minutes / 60 + seconds / 3600;
        return totalHours
    }

    const calculateTotals = useMemo(() => {
        const subtotal = totalTimeReturnData() * formData.jobRate +
            formData.materialDetails.reduce((sum, item) => sum + (item.price || 0), 0) +
            formData.equipmentDetails.reduce((sum, item) => sum + (item.price || 0), 0);

        const salesTax = (subtotal * (formData.totals.taxRate || 0)) / 100;
        const total = subtotal + salesTax - (formData.totals.deposit || 0);
        return {
            subtotal: subtotal.toFixed(2),
            salesTax: salesTax.toFixed(2),
            total: total.toFixed(2),
            deposit: formData.totals.deposit,
            taxRate: formData.totals.taxRate
        };
    }, [formData]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            totals: calculateTotals
        }));
    }, [formData.jobRate, JSON.stringify(formData.materialDetails), JSON.stringify(formData.equipmentDetails), formData.totals.taxRate, formData.totals.deposit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setErrors(prevState => ({
            ...prevState,
            [name]: ""
        }))
    };

    const handleTotalsChange = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value)) {
            setFormData(prevState => ({
                ...prevState,
                totals: {
                    ...prevState.totals,
                    [name]: parseFloat(value) || 0
                }
            }));
        }
    };

    const handleMaterialChange = (index, e) => {
        const { name, value } = e.target;
        const updatedMaterials = [...formData.materialDetails];
        updatedMaterials[index] = { ...updatedMaterials[index], [name]: parseFloat(value) || 0 };
        updatedMaterials[index].price = updatedMaterials[index].rate * updatedMaterials[index].qty;
        setFormData(prevState => ({
            ...prevState,
            materialDetails: updatedMaterials
        }));
    };

    const handleEquipmentChange = (index, e) => {
        const { name, value } = e.target;
        const updatedEquipment = [...formData.equipmentDetails];
        updatedEquipment[index] = { ...updatedEquipment[index], [name]: parseFloat(value) || 0 };
        updatedEquipment[index].price = updatedEquipment[index].rate * updatedEquipment[index].qty;
        setFormData(prevState => ({
            ...prevState,
            equipmentDetails: updatedEquipment
        }));
    };

    const handleJobDetailsChange = (e) => {
        const { name, value } = e.target;
        let totalHours = totalTimeReturnData()
        setFormData(prevState => ({
            ...prevState,
            [name]: parseFloat(value) || 0,
            jobPrice: totalHours * prevState.jobRate
        }));
    };

    const handleSubmit = (e) => {

        e.preventDefault();
        if (validateAndSetErrors()) {
            const allMaterialData = handelDataArrayToObjectUsing(formData.materialDetails)
            const allEquipmentData = handelDataArrayToObjectUsing(formData.equipmentDetails)

            for (let i = 0; i < jobMaterialDetails.MaterialUsed.length; i++) {
                const data = jobMaterialDetails.MaterialUsed[i]

                const details = {
                    id: data._id,
                    materials: data.materials.map((ele) => allMaterialData[ele._id]),
                    equipment: data.equipment.map((ele) => allEquipmentData[ele._id])
                }
                propsData.dispatch(jobActions.updateMaterialDetailsByJobId(details))
            }

            const detailsInvoice = {
                "jobId": viewRowData.id,
                "invoiceSubtotal": formData.totals.subtotal,
                "taxRate": formData.totals.taxRate,
                "salesTax": formData.totals.salesTax,
                "depositReceived": formData.totals.deposit,
                "invoiceNum": formData.invoice_number,
                "invoiceDate": formData.invoice_date,
                "taxRegistrationNo": formData.taxNumber,
                "jobRate": formData.jobRate,
                "jobPrice": totalTimeReturnData() * formData.jobRate
            }
            handelCreateApi(detailsInvoice)
            setEditInvoice(false)
        }
    };

    const [errors, setErrors] = useState({})

    const validateAndSetErrors = () => {
        const error = {}
        if (!formData.taxNumber) {
            error["taxNumber"] = "Tax Registration Number cannot be empty"
        }
        if (!formData.jobRate) {
            error["jobRate"] = "Job Rate cannot be empty"
        }

        setErrors(error)

        return Object.keys(error).length === 0
    }




    const handelDataArrayToObjectUsing = (array = []) => {
        const result = {}
        array.forEach(item => {
            const { _id, ...res } = item
            result[item._id] = res
        })

        return result
    }

    const formatTime = (hours, minutes, seconds) => {
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div style={{ padding: "0 20px", width: '80%', border: "5px solid #2e74b5", margin: "45px auto" }}>
            <form onSubmit={handleSubmit}>
                <div style={{ margin: 'auto' }}>
                    <div style={{ display: "flex", justifyContent: "space-between", margin: '20px 0' }}>
                        <img
                            src="./Logo V2.jpg"
                            alt="logo"
                            width={80}
                        />
                        <h1 style={{ paddingRight: "70px" }}>INVOICE</h1>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', textTransform: "capitalize" }}>
                        <div style={{ width: '45%' }}>
                            <p><strong>Company Name: </strong>
                                {formData.company_name}
                            </p>
                            <p><strong>Job Address: </strong>
                                {formData.job_address}
                            </p>
                            <p>Tax Registration No.:
                                <input
                                    type="text"
                                    name="taxNumber"
                                    value={formData.taxNumber}
                                    onChange={handleChange}
                                />
                                {
                                    errors && errors["taxNumber"] && <p style={{ color: "red" }}>{errors["taxNumber"]}</p>
                                }
                            </p>
                            <p><strong>Job Title:</strong>
                                {formData.job_title}
                            </p>
                        </div>
                        <div style={{ width: '50%' }}>
                            <p><strong>Invoice : </strong>#<input type="text" name="invoice_number" value={formData.invoice_number} onChange={handleChange} /></p>
                            <p><strong>Invoice date:</strong> <input type="date" name="invoice_date" value={formData.invoice_date} onChange={handleChange} /></p>
                            <p><strong>ATS Job ID No.: </strong> {formData.ats_job_id}</p>
                            <p><strong>Bill to: </strong>
                                {formData.bill_to}
                            </p>
                            <p><strong>Address: </strong>
                                {formData.address}
                            </p>
                            <p><strong>Phone: </strong>
                                {formData.phone}
                            </p>
                        </div>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Job Details</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty (hours)</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Rate</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{formData.job_details}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>
                                        {formatTime(formData.jobQtyHours, formData.jobQtyMinutes, formData.jobQtySeconds)}
                                    </td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>
                                        <input
                                            type="number"
                                            name="jobRate"
                                            value={formData.jobRate}
                                            onChange={handleJobDetailsChange}
                                        />
                                        {
                                            errors && errors["jobRate"] && <p style={{ color: "red" }}>{errors["jobRate"]}</p>
                                        }
                                    </td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>
                                        {(totalTimeReturnData() * formData.jobRate).toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Materials Description</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Per Item</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.materialDetails.map((material, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            {material.materialDescription}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            {material.qty}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            <input
                                                type="number"
                                                name="rate"
                                                value={material.rate || 0}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                            />
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            <input
                                                type="number"
                                                name="price"
                                                disabled
                                                value={material.price || 0}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Equipment Description</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Per Item</th>
                                    <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.equipmentDetails.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            {item.equipmentDescription}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            {item.qty}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            <input
                                                type="number"
                                                name="rate"
                                                value={item.rate || 0}
                                                onChange={(e) => handleEquipmentChange(index, e)}
                                            />
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '8px' }}>
                                            <input
                                                type="number"
                                                name="price"
                                                disabled
                                                value={item.price || 0}
                                                onChange={(e) => handleEquipmentChange(index, e)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: '20px', display: 'flex', justifyContent: "flex-end" }}>
                        <table style={{ width: '50%', borderCollapse: 'collapse', border: '1px solid #000' }}>
                            <tbody>
                                <tr>
                                    <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Invoice Subtotal:</th>
                                    <td style={{ padding: '8px', border: '1px solid #000' }}>
                                        {formData.totals.subtotal}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Tax Rate %:</th>
                                    <td style={{ padding: '8px', border: '1px solid #000' }}>
                                        <input
                                            type="number"
                                            name="taxRate"
                                            value={formData.totals.taxRate}
                                            onChange={handleTotalsChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Sales Tax:</th>
                                    <td style={{ padding: '8px', border: '1px solid #000' }}>
                                        {formData.totals.salesTax}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Deposit Received:</th>
                                    <td style={{ padding: '8px', border: '1px solid #000' }}>
                                        <input
                                            type="number"
                                            name="deposit"
                                            value={formData.totals.deposit}
                                            onChange={handleTotalsChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Total:</th>
                                    <td style={{ padding: '8px', border: '1px solid #000' }}>
                                        {formData.totals.total}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', marginBottom: "10px" }}>
                        <button type="submit" style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#2e74b5', color: 'white', border: 'none', borderRadius: '4px' }}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Invoice;
