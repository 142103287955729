import React, { } from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Switch,
  Grid,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Button } from "../../../../components/Wrappers/Wrappers";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

let location = [];
let id = "";

function LocationTableComponent(props) {
  let {
    // data,
    // offset,
    // updatehandleOpenCreateModal,
    // updatePwdhandleOpenCreateModal,
    // handleOpenViewModal,
    // handleSort,
    // sortOrder,
    // sortBy,
    // classes,
    // onDisable,
    locationID,
    landlord,
    handleOpenLocationCreateModal,
    locationHandler,
  } = props;

  if (landlord) {
    location = landlord.location;
    id = landlord.id;
  }

  const generateAddress = (tempdata) => {
    return `${tempdata.line1} ${tempdata.line2} ${tempdata.line3} 
    ${tempdata.city} 
    ${tempdata.state} 
    ${tempdata.pin}`;
  };

  const handleOnLocationClick = (location) => {
    // console.log("location", location);
    locationHandler(location, true);
  };

  const classes = "";
  return (
    <Paper
      className={classes.root}
      style={{ marginRight: "40px", marginTop: "45px" }}
    >
      <Grid container>
        <Grid item sm></Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenLocationCreateModal(id)}
            // className={classes.button}
            style={{ marginBottom: "10px" }}
          >
            Add Location
          </Button>
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead style={{ background: "#f6f6f6" }}>
            <TableRow>
              <TableCell style={{ cursor: "pointer" }}>Sr.no</TableCell>
              <TableCell style={{ cursor: "pointer" }}>Address</TableCell>

              <TableCell style={{ cursor: "pointer" }}>City</TableCell>
            </TableRow>
          </TableHead>
          {location.length > 0 && (
            <TableBody>
              {location &&
                location.map((tempdata, index) => (
                  <TableRow
                    key={index}
                    style={{
                      cursor: "pointer",
                      background:
                        locationID === tempdata.id ? "#f0f8ff" : "#ffffff",
                    }}
                    onClick={() => {
                      handleOnLocationClick(tempdata);
                    }}
                  >
                    <TableCell className="pl-3 fw-normal">
                      {index + 1}
                    </TableCell>

                    <TableCell className="pl-3 fw-normal">
                      {generateAddress(tempdata)}
                    </TableCell>

                    <TableCell className="pl-3 fw-normal">
                      {tempdata.city}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
          {location.length === 0 && (
            <TableBody>
              <TableCell>Data Not Found</TableCell>
            </TableBody>
          )}
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(LocationTableComponent);
