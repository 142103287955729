export function sortItems(order, sortedFieldName, data) {
  if (sortedFieldName !== null) {
    data.sort((a, b) => {
      if (order === undefined || order === "asc") {
        if (
          a[sortedFieldName].toLowerCase() < b[sortedFieldName].toLowerCase()
        ) {
          return -1;
        }
        if (
          a[sortedFieldName].toLowerCase() > b[sortedFieldName].toLowerCase()
        ) {
          return 1;
        }
        return 0;
      }
      if (order === "dsc") {
        if (
          a[sortedFieldName].toLowerCase() < b[sortedFieldName].toLowerCase()
        ) {
          return 1;
        }
        if (
          a[sortedFieldName].toLowerCase() > b[sortedFieldName].toLowerCase()
        ) {
          return -1;
        }
        return 0;
      }
    });
  }
  return data;
}
