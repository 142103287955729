export const staffConstants = {
    LOGIN_REQUEST: 'STAFFS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'STAFFS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'STAFFS_LOGIN_FAILURE',

    LOGOUT: 'STAFFS_LOGOUT',

    GETALL_REQUEST: 'STAFFS_GETALL_REQUEST',
    GETALL_SUCCESS: 'STAFFS_GETALL_SUCCESS',
    GETALL_FAILURE: 'STAFFS_GETALL_FAILURE',

    GETALL_IS_REQUEST: 'GETALL_IS_REQUEST',
    GETALL_IS_SUCCESS: 'GETALL_IS_SUCCESS',
    GETALL_IS_FAILURE: 'GETALL_IS_FAILURE',

    GETALL_ITNL_STAFF_LANDLOARD_LIST_REQUEST: 'GETALL_ITNL_STAFF_LANDLOARD_LIST_REQUEST',
    GETALL_ITNL_STAFF_LANDLOARD_LIST_SUCCESS: 'GETALL_ITNL_STAFF_LANDLOARD_LIST_SUCCESS',
    GETALL_ITNL_STAFF_LANDLOARD_LIST_FAILURE: 'GETALL_ITNL_STAFF_LANDLOARD_LIST_FAILURE',

    GETALL_ITNL_STAFF_JOB_REQUEST: 'GETALL_ITNL_STAFF_JOB_REQUEST',
    GETALL_ITNL_STAFF_JOB_SUCCESS: 'GETALL_ITNL_STAFF_JOB_SUCCESS',
    GETALL_ITNL_STAFF_JOB_FAILURE: 'GETALL_ITNL_STAFF_JOB_FAILURE',

    GET_CONTRACTOR_BY_LANDLORD_REQUEST: 'GET_CONTRACTOR_BY_LANDLORD_REQUEST',
    GET_CONTRACTOR_BY_LANDLORD_SUCCESS: 'GET_CONTRACTOR_BY_LANDLORD_SUCCESS',
    GET_CONTRACTOR_BY_LANDLORD_FAILURE: 'GET_CONTRACTOR_BY_LANDLORD_FAILURE',

    GETALL_ITNL_STAFF_LANDLOARD_REQUEST: 'GETALL_ITNL_STAFF_LANDLOARD_REQUEST',
    GETALL_ITNL_STAFF_LANDLOARD_SUCCESS: 'GETALL_ITNL_STAFF_LANDLOARD_SUCCESS',
    GETALL_ITNL_STAFF_LANDLOARD_FAILURE: 'GETALL_ITNL_STAFF_LANDLOARD_FAILURE',

    GET_ALL_STAFF_REQUEST: 'STAFFS_GET_ALL_STAFF_REQUEST',
    GET_ALL_STAFF_SUCCESS: 'STAFFS_GET_ALL_STAFF_SUCCESS',
    GET_ALL_STAFF_FAILURE: 'STAFFS_GET_ALL_STAFF_FAILURE',
    
    GETALL_STAFF_NOTIFY_REQUEST: 'GETALL_STAFF_NOTIFY_REQUEST',
    GETALL_STAFF_NOTIFY_SUCCESS: 'GETALL_STAFF_NOTIFY_SUCCESS',
    GETALL_STAFF_NOTIFY_FAILURE: 'GETALL_STAFF_NOTIFY_FAILURE',

    UPDATE_STAFF_NOTIFY_REQUEST: 'UPDATE_STAFF_NOTIFY_REQUEST',
    UPDATE_STAFF_NOTIFY_SUCCESS: 'UPDATE_STAFF_NOTIFY_SUCCESS',
    UPDATE_STAFF_NOTIFY_FAILURE: 'UPDATE_STAFF_NOTIFY_FAILURE',


    ADD_STAFF_LANDLORD_REQUEST: 'ADD_STAFF_LANDLORD_REQUEST',
    ADD_STAFF_LANDLORD_SUCCESS: 'ADD_STAFF_LANDLORD_SUCCESS',
    ADD_STAFF_LANDLORD_FAILURE: 'ADD_STAFF_LANDLORD_FAILURE',

    UPDATE_STAFF_LANDLORD_REQUEST: 'UPDATE_STAFF_LANDLORD_REQUEST',
    UPDATE_STAFF_LANDLORD_SUCCESS: 'UPDATE_STAFF_LANDLORD_SUCCESS',
    UPDATE_STAFF_LANDLORD_FAILURE: 'UPDATE_STAFF_LANDLORD_FAILURE',

    ADD_RESTAURANT_STAFF_REQUEST: 'ADD_RESTAURANT_STAFF_REQUEST',
    ADD_RESTAURANT_STAFF_SUCCESS: 'ADD_RESTAURANT_STAFF_SUCCESS',
    ADD_RESTAURANT_STAFF_FAILURE: 'ADD_RESTAURANT_STAFF_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_REQUEST: 'UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_REQUEST',
    UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_SUCCESS: 'UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_SUCCESS',
    UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_FAILURE: 'UPDATE_INTERNAL_STAFF_PASSWORD_LANDLORD_FAILURE',

    UPDATE_INTERNAL_STAFF_PASSWORD_REQUEST: 'UPDATE_INTERNAL_STAFF_PASSWORD_REQUEST',
    UPDATE_INTERNAL_STAFF_PASSWORD_SUCCESS: 'UPDATE_INTERNAL_STAFF_PASSWORD_SUCCESS',
    UPDATE_INTERNAL_STAFF_PASSWORD_FAILURE: 'UPDATE_INTERNAL_STAFF_PASSWORD_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
