import { loaderConstants } from "../_constants";

export function loader(state = { isLoading: false }, action) {
  switch (action.type) {
    case loaderConstants.START_LOADER:
      return {
        type: "start-loader",
        isLoading: action.loading,
      };
    case loaderConstants.STOP_LOADER:
      return {
        type: "stop-loader",
        isLoading: action.loading,
      };
    default:
      return state;
  }
}
