export const archiveConstants = {
  LOGIN_REQUEST: "ARCHIVE_LOGIN_REQUEST",
  LOGIN_SUCCESS: "ARCHIVE_LOGIN_SUCCESS",
  LOGIN_FAILURE: "ARCHIVE_LOGIN_FAILURE",

  LOGOUT: "ARCHIVE_LOGOUT",

  GETALL_REQUEST: "GETALL_REQUEST",
  GETALL_SUCCESS: "GETALL_SUCCESS",
  GETALL_FAILURE: "GETALL_FAILURE",

  ARCHIVE_GETALL_REQUEST: "ARCHIVE_GETALL_REQUEST",
  ARCHIVE_GETALL_SUCCESS: "ARCHIVE_GETALL_SUCCESS",
  ARCHIVE_GETALL_FAILURE: "ARCHIVE_GETALL_FAILURE",

  ON_OFF_TIME_REQUEST: "ON_OFF_TIME_REQUEST",
  ON_OFF_TIME_SUCCESS: "ON_OFF_TIME_SUCCESS",
  ON_OFF_TIME_FAILURE: "ON_OFF_TIME_FAILURE",

  ARCHIVE_GETJOBDETAILS_REQUEST: "ARCHIVE_GETJOBDETAILS_REQUEST",
  ARCHIVE_GETJOBDETAILS_SUCCESS: "ARCHIVE_GETJOBDETAILS_SUCCESS",
  ARCHIVE_GETJOBDETAILS_FAILURE: "ARCHIVE_GETJOBDETAILS_FAILURE",

};
