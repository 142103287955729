const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    float: "right",
  },
  button: {
    margin: theme.spacing(3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(8),
    flexDirection: "column",
    alignItems: "center",
  },
  title1: {
    flex: 1,
  },
  paper1: {
    marginTop: theme.spacing(4),

    flexDirection: "column",
    alignItems: "center",
  },
  table1: {
    marginRight: theme.spacing(4),
  },
  icons: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    margin: "10px 5px",
  },
  file: {
    opacity: "0",
    position: "absolute",
    height: "30px",
    marginTop: "3px",
    zIndex: "1",
    cursor: "pointer",
  },
});

export default styles;
