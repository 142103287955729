import {
    designationConstants
  } from '../_constants';
  
  export function designation(state = {}, action) {
  
    switch (action.type) {
  
  
      case designationConstants.GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.GETALL_SUCCESS:
        return {
          ...state,
          addDesignationSuccess: false,
          items: action.designations.getDesignationList.list,
          total: action.designations.getDesignationList.total
        };
      case designationConstants.GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case designationConstants.GET_ALL_DESIGNATION_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.GET_ALL_DESIGNATION_SUCCESS:
        return {
          ...state,
          getAllDesignation: action.designations.getAllDesignation
        };
      case designationConstants.GET_ALL_DESIGNATION_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case designationConstants.GETALL_DESIGNATION_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.GETALL_DESIGNATION_NOTIFY_SUCCESS:
        return {
          ...state,
          listOfNotification: action.designations.listOfNotification.list,
          listOfNotificationtotal: action.designations.listOfNotification.total
        };
      case designationConstants.GETALL_DESIGNATION_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case designationConstants.UPDATE_DESIGNATION_NOTIFY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.UPDATE_DESIGNATION_NOTIFY_SUCCESS:
        return {
          ...state,
        };
      case designationConstants.UPDATE_DESIGNATION_NOTIFY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      case designationConstants.GET_RESTAURANT_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.GET_RESTAURANT_SUCCESS:
        return {
          ...state,
          addMenuSuccess: false,
          addItemSuccess: false,
          updateitem: false,
          updateCategory: false,
          restaurantDetails: action.designations.restaurantDetails
        };
      case designationConstants.GET_RESTAURANT_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case designationConstants.ADD_RESTAURANT_DESIGNATION_REQUEST:
        return {
          ...state
        };
      case designationConstants.ADD_RESTAURANT_DESIGNATION_SUCCESS:
        return {
          ...state,
          addDesignationSuccess: true
        };
      case designationConstants.ADD_RESTAURANT_DESIGNATION_FAILURE:
        return {
          ...state,
          error: action.error
        };
      case designationConstants.ADD_MENU_REQUEST:
        return {
          ...state
        };
      case designationConstants.ADD_MENU_SUCCESS:
        return {
          ...state,
          addMenuSuccess: true
        };
      case designationConstants.ADD_MENU_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case designationConstants.ADD_ITEM_REQUEST:
        return {
          ...state
        };
      case designationConstants.ADD_ITEM_SUCCESS:
        return {
          ...state,
          addItemSuccess: true
        };
      case designationConstants.ADD_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case designationConstants.UPDATE_CATEGORY_REQUEST:
        return {
          ...state
        };
      case designationConstants.UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          updateCategory: true
        };
      case designationConstants.UPDATE_CATEGORY_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case designationConstants.UPDATE_ITEM_REQUEST:
        return {
          ...state
        };
      case designationConstants.UPDATE_ITEM_SUCCESS:
        return {
          ...state,
          updateitem: true
        };
      case designationConstants.UPDATE_ITEM_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
  
      case designationConstants.FILE_UPLOAD_STATUS_SUCCESS:
        return {
          ...state, filesDetails: action.uploadImage.filesDetails,
        };
  
      case designationConstants.FILE_UPLOAD_STATUS_FAILURE:
        return {
          ...state
        };
  
  
      case designationConstants.STATS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case designationConstants.STATS_SUCCESS:
        return {
          ...state,
          statics: action.designations.statics
        };
      case designationConstants.STATS_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
      default:
        return state
    }
  }