import { contractorLandlordConstants } from "../_constants";
import { contractorLandlordService } from "../_services";
import { alertActions, loaderActions } from ".";
export const contractorLandlordActions = {
  logout,
  getAllContractor,
  getContractorByLandord,
};

function logout() {
  contractorLandlordService.logout();
  return { type: contractorLandlordConstants.LOGOUT };
}

function getAllContractor(data) {
  return (dispatch) => {
    dispatch(request());
    contractorLandlordService.getAllContractor(data).then(
      (contractors) => {
        dispatch(success(contractors));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorLandlordConstants.GET_ALL_CONTRACTOR_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorLandlordConstants.GET_ALL_CONTRACTOR_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return {
      type: contractorLandlordConstants.GET_ALL_CONTRACTOR_FAILURE,
      error,
    };
  }
}
function getContractorByLandord(data) {
  return (dispatch) => {
    dispatch(request());
    contractorLandlordService.getContractorByLandord(data).then(
      (staffs) => dispatch(success(staffs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_REQUEST };
  }
  function success(contractors) {
    return { type: contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_SUCCESS, contractors };
  }
  function failure(error) {
    return { type: contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_FAILURE, error };
  }
}
