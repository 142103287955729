import { contractorLandlordConstants } from "../_constants";

export function contractorsLandlord(state = {}, action) {
  switch (action.type) {
    case contractorLandlordConstants.GET_ALL_CONTRACTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case contractorLandlordConstants.GET_ALL_CONTRACTOR_SUCCESS:
      return {
        ...state,
        items: action.contractors.getAllContractor.list,
        total: action.contractors.getAllContractor.total,
      };
    case contractorLandlordConstants.GET_ALL_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.error,
      };

      case contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_REQUEST:
        return {
          ...state,
          loading: true
        };
      case contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_SUCCESS:
        return {
          ...state,
          // addStaffSuccess: false,
          itemsJob: action.contractors.getContractorByLoginLandlord.list,
          total: action.contractors.getContractorByLoginLandlord.total
        };
      case contractorLandlordConstants.GET_CONTRACTOR_BY_LANDLORD_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state;
  }
}
