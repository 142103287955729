import React, { } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Select,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function AddLocationLandlordDialog(props) {
  let {
    classes,
    apiPlaces,
    formData,
    locationCreateModal,
    handleClose,
    selectedPlace,
    handleChangeInput,
    handleSubmit,
    locationHandler,
    allitems,
    handleCoordinate,
    placeDetails,
    handleChangeInputPlaceDetails
  } = props;

  console.log("MODAL_____placeDetails:", placeDetails);

  return (
    <Dialog fullScreen open={locationCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Location
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Location 1
            {/* {JSON.stringify(apiPlaces)} */}
          </Typography>





          <Grid item xs={12} sm={12}>
            <input
              label="Find "
              fullWidth
              onChange={locationHandler}
              name="apiPlaces"
              // value={selectedPlace}
            />

            {apiPlaces &&
              apiPlaces.length > 0 &&
              apiPlaces.map((element) => {
                return (
                  <MenuItem
                    onClick={() => handleCoordinate(element)}
                  // key={element.id} 
                  // name="apiPlaces"
                  // value={element}
                  >
                    {/* {JSON.stringify(element)} */}
                    {element.name},{element.region},{element.country}
                  </MenuItem>
                );
              })}

          </Grid>



          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Line 1 *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="line1"
                  id="line1"
                  value={formData.line1}
                  validators={["required"]}
                  errorMessages={["Line 1 is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Line 2 *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="line2"
                  id="line2"
                  value={formData.line2}
                  validators={["required"]}
                  errorMessages={["Line 2 is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Line 3 *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="line3"
                  id="line3"
                  value={formData.line3}
                  validators={["required"]}
                  errorMessages={["Line 3 is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="City *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="city"
                  id="city"
                  value={formData.city}
                  validators={["required"]}
                  errorMessages={["City is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Province/State *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="region"
                  id="region"
                  value={formData.region}
                  validators={["required"]}
                  errorMessages={["State is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Postal/Zip Code *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="pin"
                  id="pin"
                  value={formData.pin}
                  validators={["required"]}
                  errorMessages={["PIN code is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Country *"
                  fullWidth
                  onChange={handleChangeInput}
                  name="country"
                  id="country"
                  value={formData.country}
                  validators={["required"]}
                  errorMessages={["Country is required"]}
                />
              </Grid>
            </Grid>
            <Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                className={classes.submit}
                style={{ marginTop: "16px" }}
              >
                Save
              </Button>
            </Grid>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
