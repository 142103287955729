import { landlordConstants } from "../_constants";
import { landlordService } from "../_services";
import { alertActions, loaderActions } from "./";
import { history } from "../_helpers";
export const landlordActions = {
  login,
  logout,
  updatePassword,
  addLandlord,
  uploadImage,
  disableLandlord,
  deleteLandlord,
  updateLandlord,
  getAllLandlord,
  getLandlordList,
  getLandlordDetails,
  getTimeData,
};

function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    landlordService.login(data).then(
      (landlord) => {
        dispatch(success(landlord));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(landlord) {
    return { type: landlordConstants.LOGIN_REQUEST, landlord };
  }
  function success(landlord) {
    return { type: landlordConstants.LOGIN_SUCCESS, landlord };
  }
  function failure(error) {
    return { type: landlordConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  landlordService.logout();
  return { type: landlordConstants.LOGOUT };
}
function getTimeData(data) {
  return (dispatch) => {
    dispatch(request());
    landlordService.getTimeData(data).then(
      (landlords) => dispatch(success(landlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.ON_OFF_TIME_ADMIN_REQUEST };
  }
  function success(landlords) {
    return { type: landlordConstants.ON_OFF_TIME_ADMIN_SUCCESS, landlords };
  }
  function failure(error) {
    return { type: landlordConstants.ON_OFF_TIME_ADMIN_FAILURE, error };
  }
}
function getLandlordList(data) {
  return (dispatch) => {
    dispatch(request());
    landlordService.getLandlordList(data).then(
      (landlords) => dispatch(success(landlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_REQUEST };
  }
  function success(landlords) {
    return { type: landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_SUCCESS, landlords };
  }
  function failure(error) {
    return { type: landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_FAILURE, error };
  }
}


function getLandlordDetails(data) {
  return (dispatch) => {
    dispatch(request());
    landlordService.getLandlordDetails(data).then(
      (landlord) => {
        // console.log("Lag jayega na?", landlord);
        dispatch(success(landlord));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.GET_LANDLORD_DETAILS_REQUEST };
  }
  function success(landlord) {
    return { type: landlordConstants.GET_LANDLORD_DETAILS_SUCCESS, landlord };
  }
  function failure(error) {
    return { type: landlordConstants.GET_LANDLORD_DETAILS_FAILURE, error };
  }
}
function updatePassword(data) {
  let reqData = {
    keyWord: "",
    pageNo: 1,
    roll_id: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    landlordService.updatePassword(data).then(
      (users) => {
        // toast("Password Updated successfully.")
        dispatch(alertActions.success("Password Updated successfully."));
        dispatch(success(users));
        dispatch(landlordActions.getUserList(reqData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.UPDATE_LANDLORD_PASSWORD_REQUEST };
  }
  function success(users) {
    return { type: landlordConstants.UPDATE_LANDLORD_PASSWORD_SUCCESS, users };
  }
  function failure(error) {
    return { type: landlordConstants.UPDATE_LANDLORD_PASSWORD_FAILURE, error };
  }
}
function getAllLandlord(data) {
  return (dispatch) => {
    dispatch(request());
    landlordService.getAllLandlord(data).then(
      (landlords) => {
        //console.log("landlords  ",landlords);
        let returnResppnse = landlords.getAllLandlord
          ? landlords.getAllLandlord.map((landlord) => ({
            value: landlord.id,
            label: landlord.firstName + " " + landlord.lastName,
          }))
          : [];
        let newRespnse = { ...landlords, getAllLandlord: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.GET_ALL_LANDLORD_REQUEST };
  }
  function success(landlords) {
    return { type: landlordConstants.GET_ALL_LANDLORD_SUCCESS, landlords };
  }
  function failure(error) {
    return { type: landlordConstants.GET_ALL_LANDLORD_FAILURE, error };
  }
}
function addLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 0,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    landlordService.addLandlord(data).then(
      (landlords) => {
        dispatch(success(landlords));
        dispatch(this.getLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_REQUEST };
  }
  function success(landlords) {
    return {
      type: landlordConstants.ADD_RESTAURANT_LANDLORD_SUCCESS,
      landlords,
    };
  }
  function failure(error) {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_FAILURE, error };
  }
}
function updateLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 0,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    landlordService.updateLandlord(data).then(
      (landlords) => {
        dispatch(success(landlords));
        dispatch(this.getLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_REQUEST };
  }
  function success(landlords) {
    return {
      type: landlordConstants.ADD_RESTAURANT_LANDLORD_SUCCESS,
      landlords,
    };
  }
  function failure(error) {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_FAILURE, error };
  }
}
function disableLandlord(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    landlordService.disableLandlord(data).then(
      (landlords) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        // dispatch(success(landlords));
        // dispatch(this.getLandlordList(tempdata));
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_REQUEST };
  }
  // function success(landlords) {
  //   return {
  //     type: landlordConstants.ADD_RESTAURANT_LANDLORD_SUCCESS,
  //     landlords,
  //   };
  // }
  function failure(error) {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_FAILURE, error };
  }
}
function deleteLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    landlordService.deleteLandlord(maintempdata).then(
      (landlords) => {
        dispatch(success(landlords));
        dispatch(this.getLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_REQUEST };
  }
  function success(landlords) {
    return {
      type: landlordConstants.ADD_RESTAURANT_LANDLORD_SUCCESS,
      landlords,
    };
  }
  function failure(error) {
    return { type: landlordConstants.ADD_RESTAURANT_LANDLORD_FAILURE, error };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    landlordService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return { type: landlordConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage };
  }
  function failure(error) {
    return { type: landlordConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}
// function siteInfoFileUpload(data) {
//   // console.log("action data", data)
//   return (dispatch) => {
//     landlordService.siteInfoFileUpload(data).then(
//       (siteInfoFileUpload) => {
//         dispatch(alertActions.success("File uploaded successfully"));
//       },
//       (error) => {
//         dispatch(alertActions.error(error));
//         dispatch(failure(error));
//       },
//     );
//   };
//   function success(siteInfoFileUpload) {
//     return {
//       type: landlordConstants.SITE_INFO_FILE_UPLOAD_STATUS__SUCCESS,
//       siteInfoFileUpload,
//     };
//   }
//   function failure(error) {
//     return {
//       type: landlordConstants.SITE_INFO_FILE_UPLOAD_STATUS_FAILURE,
//       error,
//     };
//   }
// }
