export const landlordConstants = {
  LOGIN_REQUEST: "LANDLORDS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "LANDLORDS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "LANDLORDS_LOGIN_FAILURE",

  LOGOUT: "LANDLORDS_LOGOUT",

  GETALL_REQUEST: "LANDLORDS_GETALL_REQUEST",
  GETALL_SUCCESS: "LANDLORDS_GETALL_SUCCESS",
  GETALL_FAILURE: "LANDLORDS_GETALL_FAILURE",

  GET_ALL_LANDLORD_REQUEST: "LANDLORDS_GET_ALL_LANDLORD_REQUEST",
  GET_ALL_LANDLORD_SUCCESS: "LANDLORDS_GET_ALL_LANDLORD_SUCCESS",
  GET_ALL_LANDLORD_FAILURE: "LANDLORDS_GET_ALL_LANDLORD_FAILURE",

  GET_LANDLORD_LIST_FOR_ADMIN_REQUEST: "GET_LANDLORD_LIST_FOR_ADMIN_REQUEST",
  GET_LANDLORD_LIST_FOR_ADMIN_SUCCESS: "GET_LANDLORD_LIST_FOR_ADMIN_SUCCESS",
  GET_LANDLORD_LIST_FOR_ADMIN_FAILURE: "GET_LANDLORD_LIST_FOR_ADMIN_FAILURE",

  ON_OFF_TIME_ADMIN_REQUEST: "ON_OFF_TIME_ADMIN_REQUEST",
  ON_OFF_TIME_ADMIN_SUCCESS: "ON_OFF_TIME_ADMIN_SUCCESS",
  ON_OFF_TIME_ADMIN_FAILURE: "ON_OFF_TIME_ADMIN_FAILURE",

  GET_LANDLORD_DETAILS_REQUEST: "GET_LANDLORD_DETAILS_REQUEST",
  GET_LANDLORD_DETAILS_SUCCESS: "GET_LANDLORD_DETAILS_SUCCESS",
  GET_LANDLORD_DETAILS_FAILURE: "GET_LANDLORD_DETAILS_FAILURE",

  GETALL_LANDLORD_NOTIFY_REQUEST: "GETALL_LANDLORD_NOTIFY_REQUEST",
  GETALL_LANDLORD_NOTIFY_SUCCESS: "GETALL_LANDLORD_NOTIFY_SUCCESS",
  GETALL_LANDLORD_NOTIFY_FAILURE: "GETALL_LANDLORD_NOTIFY_FAILURE",

  UPDATE_LANDLORD_NOTIFY_REQUEST: "UPDATE_LANDLORD_NOTIFY_REQUEST",
  UPDATE_LANDLORD_NOTIFY_SUCCESS: "UPDATE_LANDLORD_NOTIFY_SUCCESS",
  UPDATE_LANDLORD_NOTIFY_FAILURE: "UPDATE_LANDLORD_NOTIFY_FAILURE",

  UPDATE_LANDLORD_PASSWORD_REQUEST: "UPDATE_LANDLORD_PASSWORD_REQUEST",
  UPDATE_LANDLORD_PASSWORD_SUCCESS: "UPDATE_LANDLORD_PASSWORD_SUCCESS",
  UPDATE_LANDLORD_PASSWORD_FAILURE: "UPDATE_LANDLORD_PASSWORD_FAILURE",

  ADD_RESTAURANT_LANDLORD_REQUEST: "ADD_RESTAURANT_LANDLORD_REQUEST",
  ADD_RESTAURANT_LANDLORD_SUCCESS: "ADD_RESTAURANT_LANDLORD_SUCCESS",
  ADD_RESTAURANT_LANDLORD_FAILURE: "ADD_RESTAURANT_LANDLORD_FAILURE",

  FILE_UPLOAD_STATUS_REQUEST: "FILE_UPLOAD_STATUS_REQUEST",
  FILE_UPLOAD_STATUS_SUCCESS: "FILE_UPLOAD_STATUS_SUCCESS",
  FILE_UPLOAD_STATUS_FAILURE: "FILE_UPLOAD_STATUS_FAILURE",

  GET_RESTAURANT_REQUEST: "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS: "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_FAILURE: "GET_RESTAURANT_FAILURE",

  ADD_MENU_REQUEST: "ADD_MENU_REQUEST",
  ADD_MENU_SUCCESS: "ADD_MENU_SUCCESS",
  ADD_MENU_FAILURE: "ADD_MENU_FAILURE",

  ADD_ITEM_REQUEST: "ADD_ITEM_REQUEST",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",
  ADD_ITEM_FAILURE: "ADD_ITEM_FAILURE",

  DEL_ITEM_REQUEST: "DEL_ITEM_REQUEST",
  DEL_ITEM_SUCCESS: "DEL_ITEM_SUCCESS",
  DEL_ITEM_FAILURE: "DEL_ITEM_FAILURE",

  UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",

  UPDATE_ITEM_REQUEST: "UPDATE_ITEM_REQUEST",
  UPDATE_ITEM_SUCCESS: "UPDATE_ITEM_SUCCESS",
  UPDATE_ITEM_FAILURE: "UPDATE_ITEM_FAILURE",

  STATS_REQUEST: "STATS_REQUEST",
  STATS_SUCCESS: "STATS_SUCCESS",
  STATS_FAILURE: "STATS_FAILURE",
};
