import { contractorConstants } from "../_constants";
import { contractorService } from "../_services";
import { alertActions, loaderActions } from "./";
import { history } from "../_helpers";
export const contractorActions = {
  login,
  logout,
  updatePassword,
  addContractor,
  uploadImage,
  disableContractor,
  deleteContractor,
  updateContractor,
  assignLandlord,
  getAllContractor,
  getContractorList,
  getLandlordList
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    contractorService.login(data).then(
      (contractor) => {
        dispatch(success(contractor));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(contractor) {
    return { type: contractorConstants.LOGIN_REQUEST, contractor };
  }
  function success(contractor) {
    return { type: contractorConstants.LOGIN_SUCCESS, contractor };
  }
  function failure(error) {
    return { type: contractorConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  contractorService.logout();
  return { type: contractorConstants.LOGOUT };
}
function updatePassword(data) {
  let reqData = {
    keyWord: "",
    pageNo: 1,
    roll_id: 0,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    contractorService.updatePassword(data).then(
      (users) => {
        // toast("Password Updated successfully.")
        dispatch(alertActions.success("Password Updated successfully."));
        dispatch(success(users));
        dispatch(contractorActions.getContractorList(reqData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.UPDATE_CONTRACTOR_PASSWORD_REQUEST };
  }
  function success(users) {
    return {
      type: contractorConstants.UPDATE_CONTRACTOR_PASSWORD_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: contractorConstants.UPDATE_CONTRACTOR_PASSWORD_FAILURE,
      error,
    };
  }
}
function getContractorList(data) {
  return (dispatch) => {
    dispatch(request());
    contractorService.getContractorList(data).then(
      (contractors) => dispatch(success(contractors)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.GETALL_CONTRACTOR_LIST_REQUEST };
  }
  function success(contractors) {
    return { type: contractorConstants.GETALL_CONTRACTOR_LIST_SUCCESS, contractors };
  }
  function failure(error) {
    return { type: contractorConstants.GETALL_CONTRACTOR_LIST_FAILURE, error };
  }
}
function getLandlordList(data) {
  return (dispatch) => {
    dispatch(request());
    contractorService.getLandlordList(data).then(
      (contractors) => dispatch(success(contractors)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.GETALL_LANDLORD_LIST_REQUEST };
  }
  function success(contractors) {
    return { type: contractorConstants.GETALL_LANDLORD_LIST_SUCCESS, contractors };
  }
  function failure(error) {
    return { type: contractorConstants.GETALL_LANDLORD_LIST_FAILURE, error };
  }
}
function getAllContractor(data) {
  return (dispatch) => {
    dispatch(request());
    contractorService.getAllContractor(data).then(
      (contractors) => {
        //console.log("contractors  ",contractors);
        let returnResppnse = contractors.getAllContractor
          ? contractors.getAllContractor.map((contractor) => ({
            value: contractor.id,
            label: contractor.firstName + " " + contractor.lastName,
          }))
          : [];
        let newRespnse = { ...contractors, getAllContractor: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.GET_ALL_CONTRACTOR_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorConstants.GET_ALL_CONTRACTOR_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return { type: contractorConstants.GET_ALL_CONTRACTOR_FAILURE, error };
  }
}
function addContractor(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    roll_id: 1,
  };
  return (dispatch) => {
    dispatch(request());
    contractorService.addContractor(data).then(
      (contractors) => {
        dispatch(success(contractors));
        dispatch(this.getContractorList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_FAILURE,
      error,
    };
  }
}
function assignLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    contractorService.assignLandlord(data).then(
      (contractors) => {
        dispatch(success(contractors));
        dispatch(this.getContractorList(tempdata));
        window.location.reload()
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.ASSIGN_LANDLORD_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorConstants.ASSIGN_LANDLORD_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return {
      type: contractorConstants.ASSIGN_LANDLORD_FAILURE,
      error,
    };
  }
}
function updateContractor(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    roll_id: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    contractorService.updateContractor(data).then(
      (contractors) => {
        dispatch(success(contractors));
        dispatch(this.getContractorList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_FAILURE,
      error,
    };
  }
}
function disableContractor(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    contractorService.disableContractor(data).then(
      (contractors) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        // dispatch(success(contractors));
        // dispatch(this.getContractorList(tempdata));
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
        dispatch(loaderActions.stopLoader());
      },
    );
  };
  function request() {
    return { type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_REQUEST };
  }
  // function success(contractors) {
  //   return {
  //     type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_SUCCESS,
  //     contractors,
  //   };
  // }
  function failure(error) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_FAILURE,
      error,
    };
  }
}
function deleteContractor(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    contractorService.deleteContractor(maintempdata).then(
      (contractors) => {
        dispatch(success(contractors));
        dispatch(this.getContractorList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_REQUEST };
  }
  function success(contractors) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_SUCCESS,
      contractors,
    };
  }
  function failure(error) {
    return {
      type: contractorConstants.ADD_RESTAURANT_CONTRACTOR_FAILURE,
      error,
    };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    contractorService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return {
      type: contractorConstants.FILE_UPLOAD_STATUS_SUCCESS,
      uploadImage,
    };
  }
  function failure(error) {
    return { type: contractorConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}
