import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function AddDesignationDialog(props) {
  let {
    classes,
    formData,
    userCreateModal,
    handleClose,
    handleChangeInput,
    handleSubmit,
  } = props;

  return (
    <Dialog fullScreen open={userCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add Designation
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Designation
          </Typography>

          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="name"
                  id="name"
                  value={formData.name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
