import { jobtypeConstants } from '../_constants';
import { jobtypeService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
export const jobtypeActions = {
    login,
    logout,
    addJobType,
    uploadImage,
    disableJobType,
    deleteJobType,
    updateJobType,
    getAllJobType,
    getJobTypeList
};
function login(data) {
    return dispatch => {
        dispatch(request({ data }));
        jobtypeService.login(data)
            .then(
                jobtype => {
                    dispatch(success(jobtype));
                    history.push({ pathname: '#/app/dashboard' });
                    window.location.reload();
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(jobtype) { return { type: jobtypeConstants.LOGIN_REQUEST, jobtype } }
    function success(jobtype) { return { type: jobtypeConstants.LOGIN_SUCCESS, jobtype } }
    function failure(error) { return { type: jobtypeConstants.LOGIN_FAILURE, error } }
}
function logout() {
    jobtypeService.logout();
    return { type: jobtypeConstants.LOGOUT };
}
function getJobTypeList(data) {
    return dispatch => {
        dispatch(request());
        jobtypeService.getJobTypeList(data)
            .then(
                jobtypes => dispatch(success(jobtypes)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.GETALL_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.GETALL_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.GETALL_FAILURE, error } }
}
function getAllJobType(data) {
    return dispatch => {
        dispatch(request());
        jobtypeService.getAllJobType(data)
            .then(
                jobtypes => {
                    //console.log("jobtypes  ",jobtypes);
                    let returnResppnse=
                    jobtypes.getAllJobType?jobtypes.getAllJobType.map(jobtype => ({ value: jobtype.id, label: jobtype.firstName+" "+jobtype.lastName })):[];
                    let newRespnse={...jobtypes,getAllJobType:returnResppnse}
                    dispatch(success(newRespnse))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.GET_ALL_JOBTYPE_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.GET_ALL_JOBTYPE_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.GET_ALL_JOBTYPE_FAILURE, error } }
}
function addJobType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        jobtypeService.addJobType(data)
            .then(
                jobtypes => {
                    dispatch(success(jobtypes));
                    dispatch(this.getJobTypeList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_FAILURE, error } }
}
function updateJobType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        jobtypeService.updateJobType(data)
            .then(
                jobtypes => {
                    dispatch(success(jobtypes));
                    dispatch(this.getJobTypeList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_FAILURE, error } }
}
function disableJobType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        jobtypeService.disableJobType(maintempdata)
            .then(
                jobtypes => {
                    dispatch(success(jobtypes));
                    dispatch(this.getJobTypeList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_FAILURE, error } }
}
function deleteJobType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    let maintempdata = {
        id:data.id
    }
    return dispatch => {
        dispatch(request());
        jobtypeService.deleteJobType(maintempdata)
            .then(
                jobtypes => {
                    dispatch(success(jobtypes));
                    dispatch(this.getJobTypeList(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_REQUEST } }
    function success(jobtypes) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_SUCCESS, jobtypes } }
    function failure(error) { return { type: jobtypeConstants.ADD_RESTAURANT_JOBTYPE_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        jobtypeService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: jobtypeConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: jobtypeConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
