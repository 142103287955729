import { loaderConstants } from "../_constants"; //Raman

export const loaderActions = {
  startLoader,
  stopLoader,
};

function startLoader() {
  return { type: loaderConstants.START_LOADER, loading: true };
}

function stopLoader() {
  return { type: loaderConstants.STOP_LOADER, loading: false };
}
