import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
// import { Button } from "../../../../components/Wrappers";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function TableComponent(props) {
  let {
    data,
    offset,
    classes,
    // updatehandleOpenCreateModal,
    // onDeleteClick,
    // onDisable
  } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              <TableCell>S. NO.</TableCell>
              <TableCell>NAME</TableCell>
              {/* <TableCell >job_details</TableCell>
          <TableCell >landlord_name</TableCell>
          <TableCell >designation_name</TableCell> */}
              {/* <TableCell >RATING</TableCell> */}
              {/* <TableCell >MOBILE</TableCell> */}
              {/* <TableCell style={{textAlign:'center'}}>ACTION</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((tempdata, index) => (
              <TableRow key={index}>
                <TableCell className="pl-3 fw-normal">
                  {offset + index + 1}
                </TableCell>
                <TableCell className="pl-3 fw-normal">
                  {tempdata.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
