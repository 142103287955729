import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Switch,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "../../../../components/Wrappers";
import { sortItems } from "../../../../_helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 730,
  },
});

function TableComponent(props) {
  let { data, offset, updatehandleOpenCreateModal, onDisable, classes } = props;
  let sortedData = {};
  if (data) {
    sortedData = sortItems("asc", "name", data);
  }

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead style={{ background: "#f6f6f6" }}>
          <TableRow>
            <TableCell>S. No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">
                {offset + index + 1}
              </TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>

              <TableCell>
                <Switch
                  checked={tempdata.active}
                  onChange={() => onDisable(tempdata)}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: "5px" }}
                  onClick={() => updatehandleOpenCreateModal(tempdata)}
                >
                  Update Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(TableComponent);
