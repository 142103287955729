import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const joblandlordService = {
  login,
  getContractTypeList,
  logout,
  addJobLandlord,
  uploadImage,
  getJobLandlordList,
  getFilteredAssignedJob,
  getWorkerList,
  disableJobLandlord,
  updateJobLandlord,
  deleteJobLandlord,
  getAllJobLandlord,
  getDesignationList,
  getJobList,
  getLocationList,
  getJobTypeList,
  getWorkerTypeList,
  deleteJob,
  assignWorkerForJob,
};
function logout() {
  localStorage.removeItem("joblandlord");
}
function login(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
    .then(handleResponse)
    .then((joblandlord) => {
      let joblandlordObj = {
        joblandlordinfo: joblandlord.data,
      };
      if (joblandlord.data) {
        localStorage.setItem("joblandlord", JSON.stringify(joblandlord.data));
      }

      return joblandlordObj;
    });
}
function getJobLandlordList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getJobList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        getJobLandlordList: data.data,
      };
      // console.log("i am in service", joblandlordObj);

      return joblandlordObj;
    });
}
function getFilteredAssignedJob(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  console.log("@@@@@@@@@@@@@@@@@123::", requestOptions);

  return fetch(CONST.BACKEND_URL2 + `/getFilteredAssignedJob`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        getFilteredAssignedJob: data.data,
      };
      return joblandlordObj;
    });
}
function getWorkerList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  console.log("@@@@@@@@@@@@@@@@@123::", requestOptions);

  return fetch(CONST.BACKEND_URL2 + `/getWorkerList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        getWorkerList: data.data,
      };
      return joblandlordObj;
    });
}
function getAllJobLandlord() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
  };
  return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        getAllJobLandlord: data.data,
      };

      return joblandlordObj;
    });
}
function addJobLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/createJob`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        addJobLandlord: data.data,
      };
      return joblandlordObj;
    });
}
function assignWorkerForJob(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/assignWorkerForJob`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        assignWorkerForJob: data.data,
      };
      return joblandlordObj;
    });
}
function updateJobLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updateJobLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        addJobLandlord: data.data,
      };
      return joblandlordObj;
    });
}
function disableJobLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        addJobLandlord: data.data,
      };

      return joblandlordObj;
    });
}
function deleteJob(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/deleteWorkerFromJobId`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        deleteJob: data,
      };

      return joblandlordObj;
    });
}
function deleteJobLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/deleteJobLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let joblandlordObj = {
        addJobLandlord: data.data,
      };

      return joblandlordObj;
    });
}
function uploadImage(filedata) {
  let header = new Headers({
    Authorization: authHeader().Authorization,
  });
  var data = new FormData();
  data.append("image", filedata);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: data,
  };
  return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      let joblandlordObj = {
        filesDetails: res.data,
      };
      return joblandlordObj;
    });
}
function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      // console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function getJobTypeList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getJobTypeList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let jobtypeObj = {
        getJobTypeList: data.data,
      };
      // console.log("i am in service getJobTypeList :::", jobtypeObj);

      return jobtypeObj;
    });
}
function getContractTypeList(data) {

  console.log("123456789:::", data);

  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getContractorByLandord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let jobtypeObj = {
        getContractTypeList: data.data,
      };
      console.log("i am in service getJobTypeList :::", jobtypeObj);

      return jobtypeObj;
    });
}
function getWorkerTypeList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getWorkerTypeList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let jobtypeObj = {
        getWorkerTypeList: data.data,
      };
      // console.log("i am in service getJobTypeList :::", jobtypeObj);

      return jobtypeObj;
    });
}

function getLocationList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getLocationList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let jobObj = {
        getLocationList: data.data,
      };
      // console.log("i am in service getLocationList :::", jobObj);

      return jobObj;
    });
}
function getJobList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getJobList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let jobObj = {
        getJobList: data.data,
      };
      // console.log("i am in service getJobList :::", jobObj);

      return jobObj;
    });
}

function getDesignationList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getDesignationList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let designationObj = {
        getDesignationList: data.data,
      };
      // console.log("i am in service getDesignationList :::", designationObj);

      return designationObj;
    });
}
