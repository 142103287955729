import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';


// Define your data and options outside the component
const options = {
  title: "Total Hours",
  titleTextStyle: {
    color: "gray",
    fontSize: 22,
    bold: true,
    alignment: 'center',
  },
  pieSliceText: "value",
  pieStartAngle: 30,
  tooltip: { trigger: "title" },
  slices: {
    0: { color: "#6bba60" },
    1: { color: "#63a3ce" },
  },
  legend: {
    position: 'bottom', // Position legend at the bottom
    alignment: 'center', // Center align legend
    shape: 'square', // Set legend shape to square
  }
};


const options3 = {
  title: "Total Number of Work Orders",
  titleTextStyle: {
    color: "gray",
    fontSize: 22,
    bold: true,
    alignment: 'center',
  },
  pieSliceText: "value",
  pieStartAngle: 30,
  tooltip: { trigger: "title" },
  slices: {
    0: { color: "#6bba60" },
    1: { color: "#63a3ce" },
  },
  legend: {
    position: 'bottom', // Position legend at the bottom
    alignment: 'center', // Center align legend
    shape: 'square', // Set legend shape to square
  }
};

const options4 = {
  title: "Open Work Orders",
  titleTextStyle: {
    color: "gray",
    fontSize: 22,
    bold: true,
    alignment: 'center',
  },
  pieSliceText: "value",
  pieStartAngle: 30,
  tooltip: { trigger: "title" },
  slices: {
    0: { color: "#6bba60" },
    1: { color: "#63a3ce" },
  },
  legend: {
    position: 'bottom', // Position legend at the bottom
    alignment: 'center', // Center align legend
    shape: 'square', // Set legend shape to square
  }
};

const options5 = {
  title: "Closed Work Orders",
  titleTextStyle: {
    color: "gray",
    fontSize: 22,
    bold: true,
    alignment: 'center',
  },
  pieSliceText: "value",
  pieStartAngle: 30,
  tooltip: { trigger: "title" },
  slices: {
    0: { color: "#6bba60" },
    1: { color: "#63a3ce" },
  },
  legend: {
    position: 'bottom', // Position legend at the bottom
    alignment: 'center', // Center align legend
    shape: 'square', // Set legend shape to square
  }
};


const options2 = {
  title: "Allocation of Hours",
  titleTextStyle: {
    color: "gray",
    fontSize: 22,
    bold: true,
    alignment: 'center',
  },
  series: {
    0: { axis: 'DM', color: '#6bba60' },
    1: { axis: 'PM', color: '#63a3ce' }
  },
  axes: {
    y: {
      DM: { label: 'parsecs' },
      PM: { side: 'right', label: 'apparent magnitude' }
    }
  },
  legend: {
    position: 'bottom',
    alignment: 'center',
    shape: 'square',
  },
  hAxis: {
    title: "Landlords",
    minValue: 0,
  },
  vAxis: {
    title: "Hours",
  },
};

// Define your formatData function outside the component
function formatData(dataObject) {
  const dataArray = [['Landlord', 'DM', 'PM']];

  Object.entries(dataObject).forEach(([key, value]) => {
    const row = [key, value.DM || 0, value.PM || 0];
    dataArray.push(row);
  });

  return dataArray;
}


function convertData(inputData) {
  const formattedData = [["Pac Man", "Percentage"]];
  let totalDM = 0;
  let totalPM = 0;

  Object.entries(inputData).forEach(([key, value]) => {
    Object.entries(value).forEach(([subKey, subValue]) => {
      if (subKey === "DM") {
        totalDM += subValue;
      } else if (subKey === "PM") {
        totalPM += subValue;
      }
    });
  });

  formattedData.push(["DM", totalDM]);
  formattedData.push(["PM", totalPM]);

  return formattedData;
}

function convertData2(inputData) {
  const formattedData = [["Pac Man", "Percentage"]];
  formattedData.push(["DM", inputData.DM]);
  formattedData.push(["PM", inputData.PM]);

  return formattedData;
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3dc2f7",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Dashboard = ({ users, dispatch }) => {
  const [columnChartData, setColumnChartData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartData2, setPieChartData2] = useState([]);
  const [pieChartData3, setPieChartData3] = useState([]);
  const [pieChartData4, setPieChartData4] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const classes = {}
  useEffect(() => {
    document.title = "ATS | Contractor";
    dispatch(userActions.staticsData());
    dispatch(userActions.getDashboardData());
  }, [dispatch]);

  useEffect(() => {
    if (users.dashboardData) {
      const formattedData = formatData(users.statics2?.dashBoardDataContractor.AllocationHours || {});
      const formattedData2 = convertData(users.statics2?.dashBoardDataContractor.AllocationHours || {});
      const formattedData3 = convertData2(users.statics2?.dashBoardDataContractor.TotalNumberWorkOrders || { DM: 0, PM: 0 });
      const formattedData4 = convertData2(users.statics2?.dashBoardDataContractor.OpenWorkOrders || { DM: 0, PM: 0 });
      const formattedData5 = convertData2(users.statics2?.dashBoardDataContractor.CloseWorkOrders || { DM: 0, PM: 0 });
      const tableData = users.statics2?.dashBoardDataContractor.liftOfWorkers || [];

      setTableData(tableData)
      setColumnChartData(formattedData);
      setPieChartData(formattedData2);
      setPieChartData2(formattedData3)
      setPieChartData3(formattedData4)
      setPieChartData4(formattedData5)
    }
  }, [users.dashboardData, users.statics2]);





  return (
    <>
      <div>
        <PageTitle title="Dashboard" />
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Total Workers"
              upperTitle
              disableWidgetMenu={false}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {users.staticsData ? users.staticsData.worker : 0}
                </Typography>
              </div>
            </Widget>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: "12px ", display: "flex", justifyContent: "center" }} >
        <Chart
          chartType="PieChart"
          data={pieChartData}
          options={options}
          width={"100%"}
          height={"450px"}
        />
        <Chart
          chartType="ColumnChart"
          data={columnChartData}
          options={options2}
          width={"100%"}
          height={"450px"}
        />
      </div>

      <div style={{ marginTop: "12px ", display: "flex", justifyContent: "center" }} >
        <Chart
          chartType="PieChart"
          data={pieChartData2}
          options={options3}
          width={"100%"}
          height={"450px"}
        />
        <Chart
          chartType="PieChart"
          data={pieChartData3}
          options={options4}
          width={"100%"}
          height={"450px"}
        />
      </div>

      <div style={{ marginTop: "12px ", display: "flex", justifyContent: "center" }} >
        <Chart
          chartType="PieChart"
          data={pieChartData4}
          options={options5}
          width={"100%"}
          height={"450px"}
        />
      </div>
      <TableContainer component={Paper} sx={{ width: '70%', overflow: 'hidden', margin:"auto", marginTop:2 }} >
        <Table sx={{ minWidth: 700 }} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No.</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">PM</StyledTableCell>
              <StyledTableCell align="center">DM</StyledTableCell>
              <StyledTableCell align="center">Total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.PM}</StyledTableCell>
                <StyledTableCell align="center">{row.DM}</StyledTableCell>
                <StyledTableCell align="center">{row.Total}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>
    </>
  );
};

Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15,
};

const mapStateToProps = (state) => ({
  loggingIn: state.authentication.loggingIn,
  users: state.users,
});

export default connect(mapStateToProps)(Dashboard);
