import { locationLandlordConstants } from "../_constants";

export function locationlandlords(state = {}, action) {
  switch (action.type) {
    case locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_SUCCESS:
      return {
        ...state,
        addLocationLandlordSuccess: false,
        updateLocationLandlordSuccess: false,
        items: action.AllLocationslandlord.list,
        total: action.AllLocationslandlord.total,
      };
    case locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case locationLandlordConstants.ADD_LOCATION_LANDLORD_REQUEST:
      return {
        ...state,
      };
    case locationLandlordConstants.ADD_LOCATION_LANDLORD_SUCCESS:
      return {
        ...state,
        addLocationLandlordSuccess: true,
      };
    case locationLandlordConstants.ADD_LOCATION_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case locationLandlordConstants.UPDATE_LOCATION_LANDLORD_REQUEST:
      return {
        ...state,
      };
    case locationLandlordConstants.UPDATE_LOCATION_LANDLORD_SUCCESS:
      return {
        ...state,
        updateLocationLandlordSuccess: true,
      };
    case locationLandlordConstants.UPDATE_LOCATION_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
