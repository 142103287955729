import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function AddLandlordDialog(props) {
  let {
    classes,
    formData,
    landlordCreateModal,
    handleClose,
    handleChangeInput,
    handleSubmit,
  } = props;
  //console.log("landlordCreateModal  ",landlordCreateModal);

  return (
    <Dialog fullScreen open={landlordCreateModal} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Landlord
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Landlord
          </Typography>

          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Company Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="company_name"
                  id="company_name"
                  value={formData.company_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Contact Name*"
                  id="contact_name"
                  fullWidth
                  onChange={handleChangeInput}
                  name="contact_name"
                  value={formData.contact_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Email*"
                  id="email"
                  fullWidth
                  onChange={handleChangeInput}
                  name="email"
                  value={formData.email}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Password*"
                  id="password"
                  fullWidth
                  onChange={handleChangeInput}
                  name="password"
                  type="password"
                  value={formData.password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Contact No.*"
                  id="mobile"
                  fullWidth
                  onChange={handleChangeInput}
                  name="mobile"
                  value={formData.mobile}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Address*"
                  id="address"
                  fullWidth
                  onChange={handleChangeInput}
                  name="address"
                  value={formData.address}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
