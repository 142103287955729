import { landlordConstants } from "../_constants";

export function landlords(state = {}, action) {
  switch (action.type) {
    case landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        addLandlordSuccess: false,
        landlordListItems: action.landlords.getLandlordList.list,
        total: action.landlords.getLandlordList.total,
      };
    case landlordConstants.GET_LANDLORD_LIST_FOR_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case landlordConstants.ON_OFF_TIME_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.ON_OFF_TIME_ADMIN_SUCCESS:
      return {
        ...state,
        addLandlordSuccess: false,
        onOffItems: action.landlords.getTimeData,
      };
    case landlordConstants.ON_OFF_TIME_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case landlordConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GETALL_SUCCESS:
      return {
        ...state,
        addLandlordSuccess: false,
        items: action.landlords.getLandlordList.list,
        total: action.landlords.getLandlordList.total,
      };
    case landlordConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.GET_ALL_LANDLORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GET_ALL_LANDLORD_SUCCESS:
      return {
        ...state,
        getAllLandlord: action.landlords.getAllLandlord,
      };
    case landlordConstants.GET_ALL_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.GET_LANDLORD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GET_LANDLORD_DETAILS_SUCCESS:
      return {
        ...state,
        landlordDetails: action.landlord.getLandlordDetails,
      };
    case landlordConstants.GET_LANDLORD_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.GETALL_LANDLORD_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GETALL_LANDLORD_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.landlords.listOfNotification.list,
        listOfNotificationtotal: action.landlords.listOfNotification.total,
      };
    case landlordConstants.GETALL_LANDLORD_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.UPDATE_LANDLORD_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.UPDATE_LANDLORD_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case landlordConstants.UPDATE_LANDLORD_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.landlords.restaurantDetails,
      };
    case landlordConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.UPDATE_LANDLORD_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.UPDATE_LANDLORD_PASSWORD_SUCCESS:
      return {
        ...state,
        addLandlordSuccess: true,
      };
    case landlordConstants.UPDATE_LANDLORD_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.ADD_RESTAURANT_LANDLORD_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.ADD_RESTAURANT_LANDLORD_SUCCESS:
      return {
        ...state,
        addLandlordSuccess: true,
      };
    case landlordConstants.ADD_RESTAURANT_LANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case landlordConstants.ADD_MENU_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true,
      };
    case landlordConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.ADD_ITEM_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true,
      };
    case landlordConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true,
      };
    case landlordConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state,
      };
    case landlordConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true,
      };
    case landlordConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case landlordConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        filesDetails: action.uploadImage.filesDetails,
      };

    case landlordConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
      };

    case landlordConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case landlordConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.landlords.statics,
      };
    case landlordConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
