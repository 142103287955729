import { workerConstants } from "../_constants";
import { workerService } from "../_services";
import { alertActions, loaderActions } from "./";
import { history } from "../_helpers";
export const workerActions = {
  login,
  logout,
  addWorker,
  updatePassword,
  uploadImage,
  disableWorker,
  deleteWorker,
  updateWorker,
  getAllWorker,
  getWorkerList,
  getPackageList,
  getCurrentUserPackageByUserId,
  buyPackage,
  getMostRecentUserPackByUserId,
  getUserPackageListByUser
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    workerService.login(data).then(
      (worker) => {
        dispatch(success(worker));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(worker) {
    return { type: workerConstants.LOGIN_REQUEST, worker };
  }
  function success(worker) {
    return { type: workerConstants.LOGIN_SUCCESS, worker };
  }
  function failure(error) {
    return { type: workerConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  workerService.logout();
  return { type: workerConstants.LOGOUT };
}
function getWorkerList(data) {
  return (dispatch) => {
    dispatch(request());
    workerService.getWorkerList(data).then(
      (workers) => dispatch(success(workers)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GETALL_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GETALL_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GETALL_FAILURE, error };
  }
}
function getPackageList(data) {
  return (dispatch) => {
    dispatch(request());
    workerService.getPackageList(data).then(
      (workers) => dispatch(success(workers)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GET_PACK_LIST_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GET_PACK_LIST_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GET_PACK_LIST_FAILURE, error };
  }
}
function getMostRecentUserPackByUserId(data) {
  return (dispatch) => {
    dispatch(request());
    workerService.getMostRecentUserPackByUserId(data).then(
      (workers) => dispatch(success(workers)),
      (error) => {
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GET_USER_RECENT_PACK_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GET_USER_RECENT_PACK_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GET_USER_RECENT_PACK_FAILURE, error };
  }
}
function getUserPackageListByUser(data) {
  return (dispatch) => {
    dispatch(request());
    workerService.getUserPackageListByUser(data).then(
      (workers) => dispatch(success(workers)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GET_USER_PACK_LIST_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GET_USER_PACK_LIST_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GET_USER_PACK_LIST_FAILURE, error };
  }
}
function getCurrentUserPackageByUserId() {
  return (dispatch) => {
    dispatch(request());
    workerService.getCurrentUserPackageByUserId().then(
      (workers) => dispatch(success(workers)),
      (error) => {
        // dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GET_CURRENT_USER_PACK_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GET_CURRENT_USER_PACK_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GET_CURRENT_USER_PACK_FAILURE, error };
  }
}

function updatePassword(data) {
  let reqData = {
    keyWord: "",
    pageNo: 1,
    // roll_id: 2,
    // sortOrder: "1",
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    workerService.updatePassword(data).then(
      (users) => {
        // toast("Password Updated successfully.")
        dispatch(alertActions.success("Password Updated successfully."));
        dispatch(success(users));
        dispatch(workerActions.getWorkerList(reqData));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.UPDATE_WORKER_PASSWORD_REQUEST };
  }
  function success(users) {
    return { type: workerConstants.UPDATE_WORKER_PASSWORD_SUCCESS, users };
  }
  function failure(error) {
    return { type: workerConstants.UPDATE_WORKER_PASSWORD_FAILURE, error };
  }
}

function getAllWorker(data) {
  return (dispatch) => {
    dispatch(request());
    workerService.getAllWorker(data).then(
      (workers) => {
        //console.log("workers  ",workers);
        let returnResppnse = workers.getAllWorker
          ? workers.getAllWorker.map((worker) => ({
            value: worker.id,
            label: worker.firstName + " " + worker.lastName,
          }))
          : [];
        let newRespnse = { ...workers, getAllWorker: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.GET_ALL_WORKER_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.GET_ALL_WORKER_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.GET_ALL_WORKER_FAILURE, error };
  }
}
function addWorker(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    // roll_id: 2,
  };
  return (dispatch) => {
    dispatch(request());
    workerService.addWorker(data).then(
      (workers) => {
        dispatch(success(workers));
        dispatch(this.getWorkerList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_FAILURE, error };
  }
}
function buyPackage(data, pagination) {


  return (dispatch) => {
    dispatch(request());
    workerService.buyPackage(data).then(
      (workers) => {
        dispatch(success(workers));
        dispatch(alertActions.success("Request Send..."));
        dispatch(this.getMostRecentUserPackByUserId(pagination));
        dispatch(this.getUserPackageListByUser(pagination));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.BUY_PACKAGE_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.BUY_PACKAGE_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.BUY_PACKAGE_FAILURE, error };
  }
}
function updateWorker(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
    // roll_id: 2,
  };
  return (dispatch) => {
    dispatch(request());
    workerService.updateWorker(data).then(
      (workers) => {
        dispatch(alertActions.success("User Updated successfully."));
        dispatch(success(workers));
        dispatch(this.getWorkerList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_FAILURE, error };
  }
}
function disableWorker(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    workerService.disableWorker(data).then(
      (workers) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
        dispatch(loaderActions.stopLoader());
      },
    );
  };
  function request() {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_REQUEST };
  }
  function failure(error) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_FAILURE, error };
  }
}
function deleteWorker(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    workerService.deleteWorker(maintempdata).then(
      (workers) => {
        dispatch(success(workers));
        dispatch(this.getWorkerList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_REQUEST };
  }
  function success(workers) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_SUCCESS, workers };
  }
  function failure(error) {
    return { type: workerConstants.ADD_RESTAURANT_WORKER_FAILURE, error };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    workerService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return { type: workerConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage };
  }
  function failure(error) {
    return { type: workerConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}
