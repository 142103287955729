import { joblandlordConstants } from "../_constants";
import { joblandlordService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
export const joblandlordActions = {
  getContractTypeList,
  login,
  logout,
  addJobLandlord,
  uploadImage,
  disableJobLandlord,
  deleteJobLandlord,
  updateJobLandlord,
  getAllJobLandlord,
  getJobLandlordList,
  getFilteredAssignedJob,
  getWorkerList,
  getDesignationList,
  getJobList,
  getLocationList,
  getJobTypeList,
  deleteJob,
  assignWorkerForJob,
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    joblandlordService.login(data).then(
      (joblandlord) => {
        dispatch(success(joblandlord));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(joblandlord) {
    return { type: joblandlordConstants.LOGIN_REQUEST, joblandlord };
  }
  function success(joblandlord) {
    return { type: joblandlordConstants.LOGIN_SUCCESS, joblandlord };
  }
  function failure(error) {
    return { type: joblandlordConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  joblandlordService.logout();
  return { type: joblandlordConstants.LOGOUT };
}
function getJobLandlordList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getJobLandlordList(data).then(
      (joblandlords) => dispatch(success(joblandlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GETALL_REQUEST };
  }
  function success(joblandlords) {
    return { type: joblandlordConstants.GETALL_SUCCESS, joblandlords };
  }
  function failure(error) {
    return { type: joblandlordConstants.GETALL_FAILURE, error };
  }
}
function getFilteredAssignedJob(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getFilteredAssignedJob(data).then(
      (joblandlords) => dispatch(success(joblandlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GETALL_REQUEST };
  }
  function success(joblandlords) {
    return { type: joblandlordConstants.GETALL_SUCCESS, joblandlords };
  }
  function failure(error) {
    return { type: joblandlordConstants.GETALL_FAILURE, error };
  }
}
function getWorkerList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getWorkerList(data).then(
      (joblandlords) => dispatch(success(joblandlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GET_WORKER_BY_CONTRACTOR_REQUEST };
  }
  function success(joblandlords) {
    return { type: joblandlordConstants.GET_WORKER_BY_CONTRACTOR_SUCCESS, joblandlords };
  }
  function failure(error) {
    return { type: joblandlordConstants.GET_WORKER_BY_CONTRACTOR_FAILURE, error };
  }
}
function getAllJobLandlord(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getAllJobLandlord(data).then(
      (joblandlords) => {
        //console.log("joblandlords  ",joblandlords);
        let returnResppnse = joblandlords.getAllJobLandlord
          ? joblandlords.getAllJobLandlord.map((joblandlord) => ({
            value: joblandlord.id,
            label: joblandlord.firstName + " " + joblandlord.lastName,
          }))
          : [];
        let newRespnse = { ...joblandlords, getAllJobLandlord: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GET_ALL_JOBLANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return { type: joblandlordConstants.GET_ALL_JOBLANDLORD_FAILURE, error };
  }
}
function addJobLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    joblandlordService.addJobLandlord(data).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        dispatch(this.getJobLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE,
      error,
    };
  }
}
function assignWorkerForJob(data, tempdata) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.assignWorkerForJob(data).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        dispatch(alertActions.success("Assign Successfully."));
        dispatch(this.getFilteredAssignedJob(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.ASSIGN_JOB_TO_WORKER_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.ASSIGN_JOB_TO_WORKER_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.ASSIGN_JOB_TO_WORKER_FAILURE,
      error,
    };
  }
}
function updateJobLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    joblandlordService.updateJobLandlord(data).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        dispatch(this.getJobLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE,
      error,
    };
  }
}
function disableJobLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    joblandlordService.disableJobLandlord(maintempdata).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        dispatch(this.getJobLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE,
      error,
    };
  }
}
function deleteJob(data, tempdata) {

  return (dispatch) => {
    dispatch(request());
    joblandlordService.deleteJob(data).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        console.log("joblandlordsjoblandlords", joblandlords);
        // dispatch(alertActions.success('Removed successfully!'));
        dispatch(alertActions.success(joblandlords.deleteJob.message));
        dispatch(this.getFilteredAssignedJob(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.REMOVE_JOB_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.REMOVE_JOB_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.REMOVE_JOB_FAILURE,
      error,
    };
  }
}
function deleteJobLandlord(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    joblandlordService.deleteJobLandlord(maintempdata).then(
      (joblandlords) => {
        dispatch(success(joblandlords));
        dispatch(this.getJobLandlordList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE,
      error,
    };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    joblandlordService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return {
      type: joblandlordConstants.FILE_UPLOAD_STATUS_SUCCESS,
      uploadImage,
    };
  }
  function failure(error) {
    return { type: joblandlordConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}

function getJobTypeList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getJobTypeList(data).then(
      (joblandlords) => dispatch(success(joblandlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GETALL_LANDLORD_JOBTYPE_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.GETALL_LANDLORD_JOBTYPE_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.GETALL_LANDLORD_JOBTYPE_FAILURE,
      error,
    };
  }
}
function getContractTypeList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getContractTypeList(data)
      .then(
        (joblandlords) => dispatch(success(joblandlords)),
        (error) => {
          dispatch(alertActions.error(error));
          dispatch(failure(error));
        },
      );
  };
  function request() {
    return { type: joblandlordConstants.GETALL_LANDLORD_CONTRACT_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.GETALL_LANDLORD_CONTRACT_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.GETALL_LANDLORD_CONTRACT_FAILURE,
      error,
    };
  }
}
function getLocationList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getLocationList(data).then(
      (joblandlords) => dispatch(success(joblandlords)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_FAILURE,
      error,
    };
  }
}

function getJobList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getJobList(data).then(
      (jobs) => dispatch(success(jobs)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: joblandlordConstants.JOBLANDLORDS_GETALL_REQUEST };
  }
  function success(jobs) {
    return { type: joblandlordConstants.JOBLANDLORDS_GETALL_SUCCESS, jobs };
  }
  function failure(error) {
    return { type: joblandlordConstants.JOBLANDLORDS_GETALL_FAILURE, error };
  }
}

function getDesignationList(data) {
  return (dispatch) => {
    dispatch(request());
    joblandlordService.getDesignationList(data).then(
      (designations) => dispatch(success(designations)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST,
    };
  }
  function success(designations) {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS,
      designations,
    };
  }
  function failure(error) {
    return {
      type: joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE,
      error,
    };
  }
}
