//import { createMuiTheme} from "@material-ui/core/styles";

//const theme = createMuiTheme();
//import { createMuiTheme} from "@material-ui/core/styles";

//const theme = createMuiTheme();
const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  jobPaper: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(40),
  },
  jobText: {
    marginTop: "5px",
  },
  serviceReportContainer: {
    margin: "auto",
    width: "795px",
    fontSize: "14px",

    // border: "5px solid #387BB9",
  },
  itemsContainer: {
    display: "flex",
    "& span": {
      width: "50%",
    },
    "&>:nth-child(1)": {
      fontWeight: "bold",
      width: "35%",
    },
    "&>:nth-child(2)": {
      color: "#424242",
      width: "65%",
    },
    marginBottom: "20px",
  },
  contractor: {
    fontSize: "15px",
    background: "#263c51",
    padding: "35px",
    "& div": {
      "& span": {
        color: "white !important",
      },
    },
  },
  datesSection: {
    background: "#f6f7f8",
    padding: "20px",
  },
  boldText: { display: "flex", fontSize: "bold" },
  dateBox: { display: "flex", justifyContent: "center", flexDirection: "row" },
  dateStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    marginRight: "20px",
    marginBottom: "10px",
  },
  itemDetails: {
    border: "1px solid black",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  itemLayoutOuter: {
    // display: "flex",
    // justifyContent: "space-between",
    // flexDirection: "row",
    padding: "10px",
    border: "1px solid #2E74B5",
  },
  itemLayout: {
    display: "flex",
    
    flexDirection: "column",
    // padding: "10px",
    marginBottom: "10px",
  },
  itemsContainerDate: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    "&>:nth-child(1)": {
      fontWeight: "bold",
    },
    "&>:nth-child(2)": {
      color: "#424242",
      minWidth: "45%",
    },
  },
  reportLogo: { width: "100px" },
});

export default styles;
