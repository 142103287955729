import React, { Component } from "react";
import {
  Grid,
  // Button,
  Typography,
  CssBaseline,
  TextField,
  // Input,
} from "@material-ui/core";

import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewJobDialog from "./components/ViewJobDialog/ViewJobDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { jobActions} from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();
const format = "YYYY-MM-DD";
class JobManagement extends Component {
  constructor(props) {
    document.title = "ATS | Contractor";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOnDateSelect = this.handleOnDateSelect.bind(this);
    this.handleOnSelectChange = this.handleOnSelectChange.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      jobCreateModal: false,
      updatejobCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
      updateformData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
      designation: {},
      landlords: {},
      selectedColoumn: "job_title",
      selectedDesignation: "select",
      startDate: "",
      endDate: "",
      selectField: "",
      jobs: {
        items: [],
        total: 0,
      },
      filteredJobs: {
        items: [],
        total: 0,
      },
      paginatedJobs: {
        items: [],
        total: 0,
      },
    };
  }

  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
      filter: "assignedWorker"

    };
    this.props.dispatch(jobActions.getFilteredAssignedJob(data));

   
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.jobs.addJobSuccess) {
      return {
        ...nextProps,
        formData: {
          name: "",
          address: "",
          lat: "",
          desc: "",
          long: "",
          mobile: "",
          rating: "",
          logo: "",
          openTime: "",
          closeTime: "",
          countryCode: "",
          type: "",
        },
        jobCreateModal: false,
        updatejobCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (this.props.jobs) {
        const { items, total } = this.props.jobs;
        this.setState({
          jobs: { items, total },
          filteredJobs: { items, total },
          paginatedJobs: { items: items?.slice(0, 10) },
        });
      }
    }
  }

  handleClick = (offset, page, keyWord) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        offset,
        page,
        paginatedJobs: {
          items: this.state.filteredJobs.items.slice(
            offset,
            offset + this.state.size,
          ),
          total: 10,
        },
      };
    });

  };

  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let filteredItems;
    if (value !== "" && value !== undefined) {
      filteredItems = this.state.jobs.items.filter((item) => {
        // console.log("itemmmmmm", item);
        const { job_title, job_id } = item;
        return `${job_title}-${job_id}`
          .toLowerCase()
          .includes(value.trim().toLowerCase());
      });
      // console.log("filteredJobs", filteredItems);
    } else {
      filteredItems = Object.assign([], this.state.jobs.items);
    }

    this.setState({
      filteredJobs: { items: filteredItems, total: filteredItems.length },
      paginatedJobs: {
        items: filteredItems.slice(0, this.state.size),
        total: 10,
      },
    });
  }
  // handleOpenCreateModal(data) {
  //   //   let req = {
  //   //     "jobId": "62f98a7436ea3b3f3d83c72f",
  //   //     "timeData":[
  //   //        {
  //   //     "onSiteTime":1660643405,
  //   //     "offSiteTime":1660643605
  //   //       },
  //   //       {
  //   //     "onSiteTime":1660643805,
  //   //     "offSiteTime":1660644205
  //   //       },
  //   //       {
  //   //     "onSiteTime":1660644305,
  //   //     "offSiteTime":1660644405
  //   //         }	
  //   //   ]
  //   // }
  //     let req = {
  //       "jobId": data.id,
  //       "timeData": data.timeData
  //     }
  //     console.log("table k row se kya data aa rha h:::", data);
  //     console.log("99999999999999999999999", req);
  //     this.setState({ jobCreateModal: true, viewRowData: data });
  //     this.props.dispatch(landlordActions.getTimeData(req));
  //   }

  handleOpenCreateModal(data) {
    let req = {
      "jobId": data.id,
    }
    this.setState({ jobCreateModal: true, viewRowData: data });
    // this.props.dispatch(landlordActions.getTimeData(req));
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updatejobCreateModal: true, updateformData: data });
  }
  handleClose() {
    this.setState({ jobCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatejobCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };
  handleSubmit = () => {
    let reqData = {
      firstName: this.state.formData.firstName,
      email: this.state.formData.email,
      password: this.state.formData.password,
      lastName: this.state.formData.lastName,
      gender: this.state.formData.gender,
      dob: this.state.formData.dob,
      doj: this.state.formData.doj,
      mobile: this.state.formData.mobile,
      address: this.state.formData.address,
      height: this.state.formData.height,
      weight: this.state.formData.weight,
    };
    this.props.dispatch(jobActions.addJob(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      firstName: this.state.updateformData.firstName,
      email: this.state.updateformData.email,
      lastName: this.state.updateformData.lastName,
      gender: this.state.updateformData.gender,
      dob: this.state.updateformData.dob,
      doj: this.state.updateformData.doj,
      mobile: this.state.updateformData.mobile,
      address: this.state.updateformData.address,
      height: this.state.updateformData.height,
      weight: this.state.updateformData.weight,
    };

    this.props.dispatch(jobActions.updateJob(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      jobActions.uploadImage(event.target.files[event.target.files.length - 1]),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.deleteJob(tempdata));
  }
  onDisable(data) {
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    this.props.dispatch(jobActions.disableJob(tempdata));
  }

  onChangeHandler(event) {
    const { value } = event.target;
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedColoumn: value,
          keyWord: "",
          startDate: "",
          endDate: "",
          selectField: "",
        };
      },

      () => {
        // if (this.state.selectedColoumn !== "date_filter") {
        //   let data = {
        //     pageNo: 1,
        //     size: this.state.size,
        //     columnName: this.state.selectedColoumn,
        //     keyWord: this.state.keyWord,
        //   };
        //   this.props.dispatch(jobActions.getFilteredAssignedJob(data));
        // }
      },
    );
    this.props.dispatch(jobActions.getFilteredAssignedJob());
  }

  handleOnDateSelect(date, type) {
    if (date) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            [type]: date.format(format),
            offset: 0,
          };
        },
        () => {
          if (this.state.startDate && this.state.endDate) {
            let data = {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              // pageNo: 1,
              // size: this.state.size,
            };
            this.props.dispatch(jobActions.getFilteredAssignedJob(data));
          }
        },
      );
    } else {
      this.props.dispatch(jobActions.getFilteredAssignedJob());
      // this.props.dispatch(designationActions.getDesignationList());
    }
  }

  handleOnSelectChange(event) {
    const { value } = event.target;
    let data = {
      columnName: this.state.selectedColoumn,
      keyWord: value,
      // pageNo: 1,
      // size: this.state.size,
    };
    this.props.dispatch(jobActions.getFilteredAssignedJob(data));
    this.setState((prevState) => {
      return {
        ...prevState,
        selectField: value,
        offset: 0,
      };
    });
  }

  render() {
    let { jobs, classes, landlords } = this.props;
    let { onOffItems } = landlords;
    let {  imageUrl } = jobs;

    return (
      <>
        <PageTitle title="Jobs" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item sm />
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search job title/id"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    value={this.state.keyWord}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              {this.state.filteredJobs.total &&
                this.state.filteredJobs.total > 0 ? (
                <>
                  <Table
                    data={this.state.paginatedJobs?.items}
                    offset={this.state.offset}
                    handleOpenCreateModal={this.handleOpenCreateModal}
                    onRowClick={this.onRowClick}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {this.state.filteredJobs.total &&
                this.state.filteredJobs.total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={this.state.filteredJobs.total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page, this.state.keyWord)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
    
        <ViewJobDialog
          jobCreateModal={this.state.jobCreateModal}
          classes={classes}
          onOffItems={onOffItems}
          imageUrl={imageUrl}
          viewRowData={this.state.viewRowData}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { jobs, designation, landlords } = state;
  return {
    jobs,
    designation,
    landlords,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(JobManagement)),
);
