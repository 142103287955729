import { archiveConstants } from "../_constants";

export function archives(state = {}, action) {
  switch (action.type) {
    // case archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS:
    //   return {
    //     ...state,
    //     addJobTypeSuccess: false,
    //     designationListitems: action.designations.getDesignationList.list,
    //     designationListtotal: action.designations.getDesignationList.total
    //   };
    // case archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_SUCCESS:
    //   return {
    //     ...state,
    //     addJobTypeSuccess: false,
    //     locationListitems: action.archives.getLocationList.list,
    //     locationListtotal: action.archives.getLocationList.total
    //   };
    // case archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case archiveConstants.GETALL_LANDLORD_JOBTYPE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case archiveConstants.GETALL_LANDLORD_JOBTYPE_SUCCESS:
    //   return {
    //     ...state,
    //     addJobTypeSuccess: false,
    //     jobTypeItems: action.archives.getJobTypeList.list,
    //     jobTypeTotal: action.archives.getJobTypeList.total
    //   };
    // case archiveConstants.GETALL_LANDLORD_JOBTYPE_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case archiveConstants.GETALL_LANDLORD_CONTRACT_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case archiveConstants.GETALL_LANDLORD_CONTRACT_SUCCESS:
    //   return {
    //     ...state,
    //     addJobTypeSuccess: false,
    //     contractItems: action.archives.getContractTypeList.list,
    //     contractTotal: action.archives.getContractTypeList.total
    //   };
    // case archiveConstants.GETALL_LANDLORD_CONTRACT_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };

    // case archiveConstants.GETALL_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case archiveConstants.GETALL_SUCCESS:
    //   return {
    //     ...state,
    //     addArchiveSuccess: true,
    //     items: [],
    //     total: 0,
    //   };
    // case archiveConstants.GETALL_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //   };

    case archiveConstants.ARCHIVE_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case archiveConstants.ARCHIVE_GETALL_SUCCESS:
      return {
        ...state,
        items: action.archives.getArchiveList.list,
        total: action.archives.getArchiveList.total,
        imageUrl: action.archives.getArchiveList.imageUrl,
      };
    case archiveConstants.ARCHIVE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case archiveConstants.ON_OFF_TIME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case archiveConstants.ON_OFF_TIME_SUCCESS:
      return {
        ...state,
        timeItems: action.archives.timeData,
      };
    case archiveConstants.ON_OFF_TIME_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case archiveConstants.ARCHIVE_GETJOBDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case archiveConstants.ARCHIVE_GETJOBDETAILS_SUCCESS:
      console.log("action", action)
      return {
        ...state,
        // items: action.archives.getArchiveList.list,
        // total: action.archives.getArchiveList.total,
      };
    case archiveConstants.ARCHIVE_GETJOBDETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
