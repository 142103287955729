import React, { Component } from "react";
import {
  Grid,
  // Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddJobTypeDialog from "./components/AddJobTypeDialog/AddJobTypeDialog";
import UpdateJobTypeDialog from "./components/UpdateJobTypeDialog/UpdateJobTypeDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { jobtypeActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

class JobTypeTypeManagement extends Component {
  constructor(props) {
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatehandleOpenCreateModal =
      this.updatehandleOpenCreateModal.bind(this);
    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      jobtypeCreateModal: false,
      updatejobtypeCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
      updateformData: {
        name: "",
        address: "",
        lat: "",
        desc: "",
        long: "",
        mobile: "",
        rating: "",
        logo: "",
        openTime: "",
        closeTime: "",
        countryCode: "",
        type: "",
      },
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(jobtypeActions.getJobTypeList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.jobtypes.addJobTypeSuccess) {
      return {
        ...nextProps,
        formData: {
          name: "",
          address: "",
          lat: "",
          desc: "",
          long: "",
          mobile: "",
          rating: "",
          logo: "",
          openTime: "",
          closeTime: "",
          countryCode: "",
          type: "",
        },
        jobtypeCreateModal: false,
        updatejobtypeCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      size: this.state.size,
    };
    this.props.dispatch(jobtypeActions.getJobTypeList(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
    };
    this.props.dispatch(jobtypeActions.getJobTypeList(data));
  }
  handleOpenCreateModal() {
    this.setState({ jobtypeCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updatejobtypeCreateModal: true, updateformData: data });
  }
  handleClose() {
    this.setState({ jobtypeCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatejobtypeCreateModal: false });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };
  handleSubmit = () => {
    let reqData = {
      firstName: this.state.formData.firstName,
      email: this.state.formData.email,
      password: this.state.formData.password,
      lastName: this.state.formData.lastName,
      gender: this.state.formData.gender,
      dob: this.state.formData.dob,
      doj: this.state.formData.doj,
      mobile: this.state.formData.mobile,
      address: this.state.formData.address,
      height: this.state.formData.height,
      weight: this.state.formData.weight,
    };
    this.props.dispatch(jobtypeActions.addJobType(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      firstName: this.state.updateformData.firstName,
      email: this.state.updateformData.email,
      lastName: this.state.updateformData.lastName,
      gender: this.state.updateformData.gender,
      dob: this.state.updateformData.dob,
      doj: this.state.updateformData.doj,
      mobile: this.state.updateformData.mobile,
      address: this.state.updateformData.address,
      height: this.state.updateformData.height,
      weight: this.state.updateformData.weight,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(jobtypeActions.updateJobType(reqData));
  };
  onChangeFile(event) {
    this.props.dispatch(
      jobtypeActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(jobtypeActions.deleteJobType(tempdata));
  }
  onDisable(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      size: this.state.size,
    };
    //console.log("asdf :: ",tempdata);
    this.props.dispatch(jobtypeActions.disableJobType(tempdata));
  }
  render() {
    let { jobtypes, classes } = this.props;
    let { items, total } = jobtypes;

    console.log("itemsitemsitems", items);

    return (
      <>
        <PageTitle title="JobTypes" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid item md={10} sm={10} />
                <Grid item md={2} sm={2} xs={6}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              {items && items.length > 0 ? (
                <>
                  <Table
                    data={items}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddJobTypeDialog
          jobtypeCreateModal={this.state.jobtypeCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
        />
        <UpdateJobTypeDialog
          updatejobtypeCreateModal={this.state.updatejobtypeCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { jobtypes } = state;
  return {
    jobtypes,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(JobTypeTypeManagement)),
);
