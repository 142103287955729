import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewContractorLandlordDialog from "./components/ViewContractorLandlordDialog/ViewContractorLandlordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { contractorLandlordActions, staffActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

class ContractorLandlordManagement extends Component {
  constructor(props) {
    document.title = "ATS | LandLord";
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleOpenViewCreateModal = this.handleOpenViewCreateModal.bind(this);
    this.state = {
      viewRowData: {},
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      ViewContractorLandlordCreateModal: false,
      // joblandlordCreateModal: false,
      // updatejoblandlordCreateModal: false,
      // submitted: false,
      keyWord: "",
      // formData: {
      //   job_title: "",
      //   jobType_Id: "",
      //   job_id: "",
      //   location_id: "",
      //   assign_to: "",
      //   assinged_IS: null,
      //   job_details: "",
      //   designation_Id: "",
      // },
      // updateformData: {
      //   job_title: "",
      //   jobType_Id: "",
      //   job_id: "",
      //   location_id: "",
      //   assign_to: "",
      //   assinged_IS: null,
      //   job_details: "",
      //   designation_Id: "",
      // },
    };
  }
  componentDidMount() {
    // let data = {
    //   keyWord: "",
    //   pageNo: 1,
    //   size: this.state.size,
    // };

    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 1,
      size: this.state.size,
      // sortBy: this.state.sortBy,
      // sortOrder: this.state.sortOrder
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));

    // this.props.dispatch(contractorLandlordActions.getAllContractor({}));
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.joblandlords.addJobLandlordSuccess) {
  //     return {
  //       ...nextProps,
  //       formData: {
  //         job_title: "",
  //         jobType_Id: "",
  //         job_id: "",
  //         location_id: "",
  //         assign_to: "",
  //         assinged_IS: null,
  //         job_details: "",
  //         designation_Id: "",
  //       },
  //       joblandlordCreateModal: false,
  //       updatejoblandlordCreateModal: false,
  //     };
  //   } else {
  //     return {
  //       ...nextProps,
  //     };
  //   }
  // }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      size: this.state.size,
      roll_id: 1,
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      size: this.state.size,
      roll_id: 1,
    };
    this.props.dispatch(contractorLandlordActions.getContractorByLandord(data));
  }
  // handleOpenCreateModal() {
  //   this.setState({ joblandlordCreateModal: true });
  // }
  // updatehandleOpenCreateModal(data) {
  //   this.setState({ updatejoblandlordCreateModal: true, updateformData: data });
  // }

  handleOpenViewCreateModal(data) {
    this.setState({
      ViewContractorLandlordCreateModal: true,
      viewRowData: data,
    });
  }

  handleClose() {
    this.setState({ ViewContractorLandlordCreateModal: false });
  }
  // handleAddClose() {
  //   this.setState({ joblandlordCreateModal: false });
  // }
  // updatehandleClose() {
  //   this.setState({ updatejoblandlordCreateModal: false });
  // }
  // handleChange = (event) => {
  //   const email = event.target.value;
  //   this.setState({ email });
  // };
  // handleChangeInput = (event) => {
  //   const { formData } = this.state;
  //   formData[event.target.name] = event.target.value;
  //   this.setState({ formData });
  // };
  // updatehandleChangeInput = (event) => {
  //   const { updateformData } = this.state;
  //   updateformData[event.target.name] = event.target.value;
  //   this.setState({ updateformData });
  // };
  // handleSubmit = () => {
  //   const min = 1000000;
  //   const max = 9999999;
  //   const rand = min + Math.random() * (max - min);
  //   let reqData = {
  //     job_title: this.state.formData.job_title,
  //     jobType_Id: this.state.formData.jobType_Id,
  //     job_id: rand + "",
  //     location_id: this.state.formData.location_id,
  //     assign_to: this.state.formData.assign_to,
  //     assinged_IS: null,
  //     job_details: this.state.formData.job_details,
  //     designation_Id: this.state.formData.designation_Id,
  //   };
  //   console.log("reqData  ", this.state.formData);
  //   this.props.dispatch(contractorLandlordActions.addJobLandlord(reqData));
  // };
  // updatehandleSubmit = () => {
  //   let reqData = {
  //     id: this.state.updateformData.id,
  //     firstName: this.state.updateformData.firstName,
  //     email: this.state.updateformData.email,
  //     lastName: this.state.updateformData.lastName,
  //     gender: this.state.updateformData.gender,
  //     dob: this.state.updateformData.dob,
  //     doj: this.state.updateformData.doj,
  //     mobile: this.state.updateformData.mobile,
  //     address: this.state.updateformData.address,
  //     height: this.state.updateformData.height,
  //     weight: this.state.updateformData.weight,
  //   };
  //   console.log("reqData  ", reqData);

  //   this.props.dispatch(contractorLandlordActions.updateJobLandlord(reqData));
  // };
  // onChangeFile(event) {
  //   this.props.dispatch(
  //     contractorLandlordActions.uploadImage(
  //       event.target.files[event.target.files.length - 1],
  //     ),
  //   );
  // }
  // onRowClick(data) {
  //   this.props.history.push(`/app/restaurants/${data.id}`);
  // }
  // onDeleteClick(data) {
  //   console.log(data);
  //   let tempdata = {
  //     id: data.id,
  //     keyWord: this.state.keyWord,
  //     pageNo: this.state.page,
  //     size: this.state.size,
  //   };
  //   console.log("asdf :: ", tempdata);
  //   this.props.dispatch(contractorLandlordActions.deleteJobLandlord(tempdata));
  // }
  // onDisable(data) {
  //   console.log(data);
  //   let tempdata = {
  //     id: data.id,
  //     keyWord: this.state.keyWord,
  //     pageNo: this.state.page,
  //     size: this.state.size,
  //   };
  //   //console.log("asdf :: ",tempdata);
  //   this.props.dispatch(contractorLandlordActions.disableJobLandlord(tempdata));
  // }
  render() {
    let { contractorsLandlord, classes,internalstaff } = this.props;
    let { itemsJob, total } = contractorsLandlord;
    // console.log("internalstaffinternalstaff_________________111111111111",internalstaff)
    // let { itemsJob, total } = contractorsLandlord;
    return (
      <>
        <PageTitle title="Contractors" />
        <Grid container>
          <Grid item md={12} xs={12}>
            <Widget>
              <Grid container>
                <Grid sm></Grid>
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search Company Name"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              {itemsJob && itemsJob.length > 0 ? (
                <>
                  <Table
                    data={itemsJob}
                    offset={this.state.offset}
                    handleOpenViewCreateModal={this.handleOpenViewCreateModal}
                    onDeleteClick={this.onDeleteClick}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
            </Widget>
          </Grid>
        </Grid>
        <ViewContractorLandlordDialog
          jobCreateModal={this.state.ViewContractorLandlordCreateModal}
          classes={classes}
          viewRowData={this.state.viewRowData}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { contractorsLandlord, } = state;
  return {
    contractorsLandlord,
    // contractorsLandlord
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ContractorLandlordManagement)),
);
