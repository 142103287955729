import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Typography,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function UpdateWorkerDialog(props) {
  let {
    classes,
    updateformData,
    // parentId,
    updateuserCreateModal,
    updatehandleClose,
    updatehandleChangeInput,
    updatehandleSubmit,
    contractors,
  } = props;

  // console.log("parentId: ", parentId);

  return (
    <Dialog fullScreen open={updateuserCreateModal} onClose={updatehandleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={updatehandleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update Worker
          </Typography>
          <Button color="inherit" onClick={updatehandleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Worker
          </Typography>

          <ValidatorForm onSubmit={updatehandleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="First Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="first_name"
                  id="first_name"
                  value={updateformData.first_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Last Name*"
                  id="last_name"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="last_name"
                  value={updateformData.last_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Email*"
                  id="email"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="email"
                  disabled={true}
                  value={updateformData.email}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Contact No.*"
                  id="contact"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="contact"
                  value={updateformData.contact}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Address*"
                  id="address"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="address"
                  value={updateformData.address}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={"classes.formControl"}
                  style={{ width: "100%" }}
                >
                  {/* <SelectValidator
                    label="Contractor*"
                    fullWidth
                    labelId="select-contractor-label"
                    id="select-contractor"
                    name="parentId"
                    value={
                      updateformData.contractor
                        ? updateformData.contractor.id
                          ? updateformData.contractor.id
                          : ""
                        : ""
                    }
                    onChange={updatehandleChangeInput}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                  >
                    <MenuItem value={""}>Please Select</MenuItem>
                    {contractors &&
                      contractors.length > 0 &&
                      contractors.map((contractor) => {
                        return (
                          <MenuItem key={contractor.id} value={contractor.id}>
                            {contractor.company_name
                              ? contractor.company_name
                              : "n/a"}
                          </MenuItem>
                        );
                      })}
                  </SelectValidator> */}
                </FormControl>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
