import React, { Component } from "react";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageTitle from "../../components/PageTitle/PageTitle";
import AddContractorDialog from "./components/AddContractorDialog/AddContractorDialog";
import UpdateContractorDialog from "./components/UpdateContractorDialog/UpdateContractorDialog";
import UpdateLandlordDialog from "./components/UpdateLandlordDialog/UpdateLandlordDialog";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog/UpdatePasswordDialog";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { contractorActions } from "../../_actions";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import styles from "./styles";
const theme = createMuiTheme();

class ContractorManagement extends Component {
  constructor(props) {
    document.title = "ATS | Contractor";
    super(props);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleOpenCreateModal = this.handleOpenCreateModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDisable = this.onDisable.bind(this);
    this.landlordAssignModal = this.landlordAssignModal.bind(this);
    this.updatehandleChangeInput = this.updatehandleChangeInput.bind(this);
    this.updatepwdhandleChangeInput =
      this.updatepwdhandleChangeInput.bind(this);
    this.updatehandleSubmit = this.updatehandleSubmit.bind(this);
    this.updatehandleClose = this.updatehandleClose.bind(this);
    this.updatePwdhandleClose = this.updatePwdhandleClose.bind(this);
    this.updatehandleOpenCreateModal = this.updatehandleOpenCreateModal.bind(this);
    this.updatePwdhandleOpenCreateModal =
      this.updatePwdhandleOpenCreateModal.bind(this);

    this.state = {
      markers: [],
      places: [],
      offset: 0,
      size: 10,
      page: 1,
      email: "",
      contractorsId: "",
      sortBy: "company_name",
      sortOrder: "1",
      contractorCreateModal: false,
      // landlordAssignModal: false,
      updatecontractorCreateModal: false,
      landlordAssigningModal: false,
      updatePasswordCreateModal: false,
      submitted: false,
      keyWord: "",
      formData: {
        company_name: "",
        contact_name: "",
        landlordList: [],
        email: "",
        password: "",
        address: "",
        contact: "",
        roll_id: "",
        // land_id: "",
      },
      updateformData: {
        id: "",
        company_name: "",
        contact_name: "",
        email: "",
        password: "",
        address: "",
        contact: "",
        roll_id: "",
        landlordList: [],
      },
      updatePwdformData: {
        id: "",
        password: "",
      },
      // assignLandlord: {
      //   id: "",
      //   password: "",
      // },
    };
  }
  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 1,
      size: this.state.size,
      // sortBy: "company_name",
      // sortOrder: "1",
    };
    let reqData = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100,
      "roll_id": 0
    };
    this.props.dispatch(contractorActions.getContractorList(data));
    this.props.dispatch(contractorActions.getLandlordList(reqData));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.contractors.addContractorSuccess) {
      return {
        ...nextProps,
        formData: {
          company_name: "",
          contact_name: "",
          email: "",
          password: "",
          address: "",
          contact: "",
          roll_id: "",
        },
        contractorCreateModal: false,
        updatecontractorCreateModal: false,
        landlordAssigningModal: false,
        updatePasswordCreateModal: false,
      };
    } else {
      return {
        ...nextProps,
      };
    }
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      keyWord: this.state.keyWord,
      pageNo: page,
      roll_id: 1,
      size: this.state.size,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder
    };
    this.props.dispatch(contractorActions.getContractorList(data));
  };
  handleSearch(event) {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      keyWord: value,
      pageNo: 1,
      roll_id: 1,
      size: this.state.size,
    };
    this.props.dispatch(contractorActions.getContractorList(data));
  }
  handleOpenCreateModal() {
    this.setState({ contractorCreateModal: true });
  }
  updatehandleOpenCreateModal(data) {
    this.setState({ updatecontractorCreateModal: true, updateformData: data });
  } s
  // landlordAssignModal=(data)=> {
  //   this.setState({ landlordAssigningModal: true });
  // }
  landlordAssignModal(data) {
    console.log("-------------------------ID:", data);
    this.setState({ landlordAssigningModal: true, contractorsId: data.id });
  }
  handleClose() {
    this.setState({ contractorCreateModal: false });
  }
  updatehandleClose() {
    this.setState({ updatecontractorCreateModal: false });
  }
  updateLandlordClose = () => {
    this.setState({ landlordAssigningModal: false });
  }
  updatePwdhandleClose() {
    this.setState({ updatePasswordCreateModal: false });
  }
  updatePwdhandleOpenCreateModal(data) {
    console.log("datadatadatadata", data);
    this.setState({ updatePasswordCreateModal: true, updatePwdformData: data });
  }
  handleChange = (event) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    console.log(event.target.name, event.target.value);
    this.setState({ formData });
  };
  handleAssignLandlord = (event) => {
    console.log("handleAssignLandlord_____________called");
    const { landlord } = this.state;
    event.target.name = event.target.value;
    console.log(event.target.name, event.target.value);
    this.setState({ landlord });
  };
  updatehandleChangeInput = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  };

  updatepwdhandleChangeInput = (event) => {
    console.log("eventeventeventevent", event.target.value);
    const { updatePwdformData } = this.state;
    updatePwdformData[event.target.name] = event.target.value;
    this.setState({ updatePwdformData });
  };

  handleSort(columnName) {
    let sortOrder = this.state.sortOrder;
    sortOrder = sortOrder === "-1" ? "1" : sortOrder === "1" ? "-1" : "";

    this.setState({ page: 1, sortOrder, sortBy: columnName });
    let data = {
      keyWord: "",
      pageNo: 1,
      roll_id: 1,
      size: this.state.size,
    };
    if (sortOrder === "-1" || sortOrder === "1") {
      data["sortOrder"] = sortOrder;
      data["sortBy"] = columnName;
    }

    this.props.dispatch(contractorActions.getContractorList(data));
  }

  handleSubmit = () => {
    let reqData = {

      company_name: this.state.formData.company_name,
      contact_name: this.state.formData.contact_name,
      email: this.state.formData.email,
      password: this.state.formData.password,
      address: this.state.formData.address,
      contact: this.state.formData.contact,
      // landlord: this.state.formData.landlord,
      // land_id: this.state.formData.land_id,
      roll_id: 1,
    };
    // console.log("reqDatareqData#$%^", reqData);
    this.props.dispatch(contractorActions.addContractor(reqData));
  };
  updatehandleSubmit = () => {
    let reqData = {
      id: this.state.updateformData.id,
      company_name: this.state.updateformData.company_name,
      contact_name: this.state.updateformData.contact_name,
      address: this.state.updateformData.address,
      contact: this.state.updateformData.contact,
      // "roll_id": 1
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(contractorActions.updateContractor(reqData));
  };
  landlordAssignSubmit = () => {
    let reqData = {
      id: this.state.contractorsId,
      landlordList: this.state.formData.landlordList,
    };
    console.log("reqData  ", reqData);

    this.props.dispatch(contractorActions.assignLandlord(reqData));
  };

  updatepwdhandleSubmit = () => {
    let reqData = {
      id: this.state.updatePwdformData.id,
      password: this.state.updatePwdformData.password,
    };
    console.log("reqDatareqaupdatepwdhandleSubmit  ", reqData);

    this.props.dispatch(contractorActions.updatePassword(reqData));
  };

  onChangeFile(event) {
    this.props.dispatch(
      contractorActions.uploadImage(
        event.target.files[event.target.files.length - 1],
      ),
    );
  }
  onRowClick(data) {
    this.props.history.push(`/app/restaurants/${data.id}`);
  }
  onDeleteClick(data) {
    console.log(data);
    let tempdata = {
      id: data.id,
      keyWord: this.state.keyWord,
      pageNo: this.state.page,
      roll_id: 1,
      size: this.state.size,
    };
    console.log("asdf :: ", tempdata);
    this.props.dispatch(contractorActions.deleteContractor(tempdata));
  }
  onDisable(data) {
    let tempdata = {
      resourceId: data.id,
      resourceType: "user",
      value: !data.active,
    };
    this.props.dispatch(contractorActions.disableContractor(tempdata));
  }

  handleAssignLandlordDropDown = (event, val) => {

    console.log("eventevent:::::", event);
    console.log("Confirm List:::::", val);
    const { formData, 
      // updateformData 
    } = this.state;
    if (val.option) {
      formData.landlordList.push(val.option.id);
      // updateformData.landlordList.push(val.option.id)
      this.setState({ formData });
    }



    else if (val.removedValue) {
      formData.landlordList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.landlordList.splice(delIndex, 1);
        }
      })

      // updateformData.landlordList.forEach((deleteId, delIndex) => {
      //   if (deleteId === val.removedValue.id) {
      //     updateformData.landlordList.splice(delIndex, 1);
      //   }
      // })

      this.setState({ formData, 
        // updateformData
       });
    }
  }


  render() {
    let { contractors, classes } = this.props;
    let { items, total, getLandlordListItems } = contractors;

    // console.log("getLandlordListItems____________--$$$$", getLandlordListItems);
    // console.log("items____________--$$$$", items);
    // console.log(" this.state.formData____________--$$$$", this.state.formData);

    // console.log("111111111111111___landlordAssigningModal", this.state.landlordAssigningModal);
    console.log("111111111111111___formData", this.state.formData);
    console.log("RENDER__contractorsId___formData", this.state.contractorsId);
    console.log("RENDER__contractorsId___formData1111111111111", this.state.landlordList);


    return (
      <>
        <PageTitle title="Contractors" />
        <Grid container>
          <Grid item xs={12} md={12}>
            <Widget>
              <Grid container>
                <Grid item sm />
                <Grid item>
                  <TextField
                    id="standard-search"
                    label="Search email"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    // className={classes.textField}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleOpenCreateModal()}
                    // className={classes.button}
                    style={{ marginTop: "28px", marginLeft: "15px" }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>

              {items && items.length > 0 ? (
                <>
                  <Table
                    sortOrder={this.state.sortOrder}
                    sortBy={this.state.sortBy}
                    data={items}
                    offset={this.state.offset}
                    onRowClick={this.onRowClick}
                    updatehandleOpenCreateModal={
                      this.updatehandleOpenCreateModal
                    }
                    landlordAssignModal={this.landlordAssignModal}
                    updatePwdhandleOpenCreateModal={
                      this.updatePwdhandleOpenCreateModal
                    }
                    onDeleteClick={this.onDeleteClick}
                    handleSort={this.handleSort}
                    onDisable={this.onDisable}
                  />
                </>
              ) : (
                <Typography>Data not found.</Typography>
              )}
              {total && total > 10 ? (
                <MuiThemeProvider theme={theme}>
                  <CssBaseline />
                  <Pagination
                    limit={this.state.size}
                    offset={this.state.offset}
                    total={total}
                    onClick={(e, offset, page) =>
                      this.handleClick(offset, page)
                    }
                  />
                </MuiThemeProvider>
              ) : null}
            </Widget>
          </Grid>
        </Grid>
        <AddContractorDialog
          contractorCreateModal={this.state.contractorCreateModal}
          classes={classes}
          formData={this.state.formData}
          handleSubmit={this.handleSubmit}
          handleChangeInput={this.handleChangeInput}
          handleClose={this.handleClose}
          getLandlordListItems={getLandlordListItems}
        />
        <UpdateContractorDialog
          updateuserCreateModal={this.state.updatecontractorCreateModal}
          classes={classes}
          updateformData={this.state.updateformData}
          updatehandleSubmit={this.updatehandleSubmit}
          updatehandleChangeInput={this.updatehandleChangeInput}
          updatehandleClose={this.updatehandleClose}
          getLandlordListItems={getLandlordListItems}
        />
        <UpdateLandlordDialog
          assignLandlordModal={this.state.landlordAssigningModal}
          classes={classes}
          data={items}
          updateformData={this.state.updateformData}
          landlord={this.state.landlord}
          landlordAssignSubmit={this.landlordAssignSubmit}
          // updatehandleChangeInput={this.updatehandleChangeInput}
          handleAssignLandlordDropDown={this.handleAssignLandlordDropDown}
          updateLandlordClose={this.updateLandlordClose}
          handleAssignLandlord={this.handleAssignLandlord}
          getLandlordListItems={getLandlordListItems}
        />
        <UpdatePasswordDialog
          updateuserCreateModal={this.state.updatePasswordCreateModal}
          classes={classes}
          updatePwdformData={this.state.updatePwdformData}
          updatepwdhandleSubmit={this.updatepwdhandleSubmit}
          updatepwdhandleChangeInput={this.updatepwdhandleChangeInput}
          updatePwdhandleClose={this.updatePwdhandleClose}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { contractors } = state;
  return {
    contractors,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(ContractorManagement)),
);
