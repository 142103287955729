import { locationLandlordConstants } from "../_constants";
import { locationLandlordService } from "../_services";
import { alertActions, landlordActions } from ".";
import { history } from "../_helpers";

export const locationLandlordActions = {
  login,
  logout,
  getAllLocationsLandlord,
  addLocationLandlord,
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    locationLandlordService.login(data).then(
      (joblandlord) => {
        dispatch(success(joblandlord));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(locationlandlord) {
    return { type: locationLandlordConstants.LOGIN_REQUEST, locationlandlord };
  }
  function success(locationlandlord) {
    return { type: locationLandlordConstants.LOGIN_SUCCESS, locationlandlord };
  }
  function failure(error) {
    return { type: locationLandlordConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  locationLandlordService.logout();
  return { type: locationLandlordConstants.LOGOUT };
}
function getAllLocationsLandlord(data) {
  return (dispatch) => {
    dispatch(request());
    locationLandlordService.getAllLocationsLandlord(data).then(
      (locationsLandlord) => {
        console.log(
          "getAllLocationsLandlord locationlandlord",
          locationsLandlord,
        );
        let returnResppnse = locationsLandlord.getAllLocationsLandlord
          ? locationsLandlord.getAllLocationsLandlord
          : [];
        let newRespnse = {
          ...returnResppnse,
        };
        dispatch(success(newRespnse));
      },
      (error) => {
        console.log("getAllLocationsLandlord error", error);
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return {
      type: locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_REQUEST,
    };
  }
  function success(AllLocationslandlord) {
    return {
      type: locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_SUCCESS,
      AllLocationslandlord,
    };
  }
  function failure(error) {
    return {
      type: locationLandlordConstants.GET_ALL_LOCATIONS_LANDLORD_FAILURE,
      error,
    };
  }
}

function addLocationLandlord(data) {
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  console.log("data hum:", data);
  return (dispatch) => {
    dispatch(request());
    locationLandlordService.addLocationLandlord(data).then(
      (locationsLandlord) => {
        console.log("success gufraan", locationsLandlord);
        dispatch(success(locationsLandlord));
        dispatch(landlordActions.getLandlordDetails({ id: data.created_by }));
      },
      (error) => {
        console.log("error gufraan", error);
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: locationLandlordConstants.ADD_LOCATION_LANDLORD_REQUEST };
  }
  function success(joblandlords) {
    return {
      type: locationLandlordConstants.ADD_LOCATION_LANDLORD_SUCCESS,
      joblandlords,
    };
  }
  function failure(error) {
    return {
      type: locationLandlordConstants.ADD_LOCATION_LANDLORD_FAILURE,
      error,
    };
  }
}

// function updateJobLandlord(data) {
//   let tempdata = {
//     keyWord: "",
//     pageNo: 1,
//     size: 10,
//   };
//   return (dispatch) => {
//     dispatch(request());
//     joblandlordService.updateJobLandlord(data).then(
//       (joblandlords) => {
//         dispatch(success(joblandlords));
//         dispatch(this.getJobLandlordList(tempdata));
//       },
//       (error) => {
//         dispatch(alertActions.error(error));
//         dispatch(failure(error));
//       },
//     );
//   };
//   function request() {
//     return { type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST };
//   }
//   function success(joblandlords) {
//     return {
//       type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS,
//       joblandlords,
//     };
//   }
//   function failure(error) {
//     return {
//       type: joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE,
//       error,
//     };
//   }
// }
