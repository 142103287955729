import React, { useState } from "react";
// import { Button } from "../../../../components/Wrappers/Wrappers";
import floorPlan from "../../../../images/floor-plan.svg";
import roofPlan from "../../../../images/roof-plan.svg";
import Fire from "../../../../images/fire.svg";
import Electrical from "../../../../images/electrical.svg";
import Sprinkler from "../../../../images/sprinkler.svg";
import Boiler from "../../../../images/boiler.svg";
import HVAC from "../../../../images/hvac.svg";
import LandlordDocumentsDialog from "../LandlordDocumentsDialog/LandlordDocumentsDialog";

const LandlordMoreDetails = (props) => {
  const { classes, location, landlord, siteInfoData, locationHandler } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [siteInfo, setSiteInfo] = useState({
    type: "",
    data: { fileNames: [], fileLinks: [] },
  });

  const handleShow = (type) => {
    console.log('typetype________',type);
    const fileNames = siteInfoData[type] || [];
    const fileLinks = siteInfoData[`${type}FileLinks`] || [];
    setSiteInfo((prevState) => {
      return {
        ...prevState,
        type: type,
        data: {
          fileNames,
          fileLinks,
        },
      };
    });
    setShowDialog(true);
  };
  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <>
      <p>
        <b>Site Info: </b>
        {`${location.line1} ${location.line2} ${location.line3} ${location.city}`}
      </p>
      <div
        style={{
          backgroundColor: "#f6f7f8",
          minHeight: "100vh",
          minWidth: "622px",
          padding: "100px 30px 100px 15px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className={classes.icons}
            onClick={() => handleShow("floorPlans")}
          >
            <img src={floorPlan} width="183px" alt=" "/>
          </button>
          <button
            className={(classes.iconSpace, classes.icons)}
            onClick={() => handleShow("roofPlans")}
          >
            <img src={roofPlan} width="183px"  alt=" "/>
          </button>
          <button
            className={classes.icons}
            onClick={() => handleShow("fireCertification")}
          >
            <img src={Fire} width="183px" alt=" "/>
          </button>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className={classes.icons}
            onClick={() => handleShow("electricalRoom")}
          >
            <img src={Electrical} width="183px" height="" alt=" "/>
          </button>

          <button
            className={(classes.iconSpace, classes.icons)}
            onClick={() => handleShow("sprinklerRoom")}
          >
            <img src={Sprinkler} width="183px" alt=" "/>
          </button>
          <button
            className={classes.icons}
            onClick={() => handleShow("boilerRoom")}
          >
            <img src={Boiler} width="183px" alt=" "/>
          </button>
        </div>
        <div>
          <button
            className={classes.icons}
            onClick={() => handleShow("hvacEquipment")}
          >
            <img src={HVAC} width="183px" height="" alt=" "/>
          </button>
        </div>
      </div>
      {showDialog && (
        <LandlordDocumentsDialog
          handleClose={handleClose}
          show={showDialog}
          classes={classes}
          siteInfo={siteInfo}
          location={location}
          landlordId={landlord.id}
          locationHandler={locationHandler}
        />
      )}
    </>
  );
};

export default LandlordMoreDetails;
