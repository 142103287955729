import { archiveConstants } from "../_constants";
import { archiveService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
export const archiveActions = {
  //   getContractTypeList,
  //   login,
  logout,
  //   addArchive,
  //   uploadImage,
  //   disableArchive,
  //   deleteArchive,
  //   updateArchive,
  //   getAllArchive,
  getArchiveList,
  getTimeData,
  getJobDetails,
  //   getDesignationList,
  //   getJobList,
  //   getLocationList,
  //   getJobTypeList,
};
// function login(data) {
//   return (dispatch) => {
//     dispatch(request({ data }));
//     archiveService.login(data).then(
//       (archive) => {
//         dispatch(success(archive));
//         history.push({ pathname: "#/app/dashboard" });
//         window.location.reload();
//       },
//       (error) => {
//         console.log("errorerror ", error);
//         dispatch(failure(error));
//         dispatch(alertActions.error(error));
//       },
//     );
//   };

//   function request(archive) {
//     return { type: archiveConstants.LOGIN_REQUEST, archive };
//   }
//   function success(archive) {
//     return { type: archiveConstants.LOGIN_SUCCESS, archive };
//   }
//   function failure(error) {
//     return { type: archiveConstants.LOGIN_FAILURE, error };
//   }
// }
function logout() {
  archiveService.logout();
  return { type: archiveConstants.LOGOUT };
}

function getTimeData(data) {
  return (dispatch) => {
    dispatch(request());
    archiveService.getTimeData(data).then(
      (archives) => {
        dispatch(success(archives));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: archiveConstants.ON_OFF_TIME_REQUEST };
  }
  function success(archives) {
    return { type: archiveConstants.ON_OFF_TIME_SUCCESS, archives };
  }
  function failure(error) {
    return { type: archiveConstants.ON_OFF_TIME_FAILURE, error };
  }
}
function getArchiveList(data) {
  return (dispatch) => {
    dispatch(request());
    archiveService.getArchiveList(data).then(
      (archives) => {
        dispatch(success(archives));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: archiveConstants.ARCHIVE_GETALL_REQUEST };
  }
  function success(archives) {
    return { type: archiveConstants.ARCHIVE_GETALL_SUCCESS, archives };
  }
  function failure(error) {
    return { type: archiveConstants.ARCHIVE_GETALL_FAILURE, error };
  }
}

function getJobDetails(id) {
  return (dispatch) => {
    dispatch(request());
    archiveService.getJobDetails(id).then(
      (workerWorkDetails) => {
        console.log("workerWorkDetails", workerWorkDetails);
        dispatch(success(workerWorkDetails));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: archiveConstants.ARCHIVE_GETJOBDETAILS_REQUEST };
  }
  function success(workerWorkDetails) {
    return { type: archiveConstants.ARCHIVE_GETJOBDETAILS_SUCCESS, workerWorkDetails };
  }
  function failure(error) {
    return { type: archiveConstants.ARCHIVE_GETJOBDETAILS_FAILURE, error };
  }
}
// function getAllArchive(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getAllArchive(data)
//             .then(
//                 archives => {
//                     //console.log("archives  ",archives);
//                     let returnResppnse =
//                         archives.getAllArchive ? archives.getAllArchive.map(archive => ({ value: archive.id, label: archive.firstName + " " + archive.lastName })) : [];
//                     let newRespnse = { ...archives, getAllArchive: returnResppnse }
//                     dispatch(success(newRespnse))
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.GET_ALL_JOBLANDLORD_REQUEST } }
//     function success(archives) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_FAILURE, error } }
// }
// function addArchive(data) {
//     let tempdata = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
//     }
//     return dispatch => {
//         dispatch(request());
//         archiveService.addArchive(data)
//             .then(
//                 archives => {
//                     dispatch(success(archives));
//                     dispatch(this.getArchiveList(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST } }
//     function success(archives) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE, error } }
// }
// function updateArchive(data) {
//     let tempdata = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
//     }
//     return dispatch => {
//         dispatch(request());
//         archiveService.updateArchive(data)
//             .then(
//                 archives => {
//                     dispatch(success(archives));
//                     dispatch(this.getArchiveList(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST } }
//     function success(archives) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE, error } }
// }
// function disableArchive(data) {
//     let tempdata = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
//     }
//     let maintempdata = {
//         id: data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         archiveService.disableArchive(maintempdata)
//             .then(
//                 archives => {
//                     dispatch(success(archives));
//                     dispatch(this.getArchiveList(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST } }
//     function success(archives) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE, error } }
// }
// function deleteArchive(data) {
//     let tempdata = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
//     }
//     let maintempdata = {
//         id: data.id
//     }
//     return dispatch => {
//         dispatch(request());
//         archiveService.deleteArchive(maintempdata)
//             .then(
//                 archives => {
//                     dispatch(success(archives));
//                     dispatch(this.getArchiveList(tempdata));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST } }
//     function success(archives) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE, error } }
// }
// function uploadImage(data) {
//     return dispatch => {
//         archiveService.uploadImage(data)
//             .then(
//                 uploadImage => {
//                     dispatch(success(uploadImage));
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function success(uploadImage) { return { type: archiveConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
//     function failure(error) { return { type: archiveConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
// }

// function getJobTypeList(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getJobTypeList(data)
//             .then(
//                 archives => dispatch(success(archives)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.GETALL_LANDLORD_JOBTYPE_REQUEST } }
//     function success(archives) { return { type: archiveConstants.GETALL_LANDLORD_JOBTYPE_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.GETALL_LANDLORD_JOBTYPE_FAILURE, error } }
// }
// function getContractTypeList(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getContractTypeList(data)
//             .then(
//                 archives => dispatch(success(archives)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.GETALL_LANDLORD_CONTRACT_REQUEST } }
//     function success(archives) { return { type: archiveConstants.GETALL_LANDLORD_CONTRACT_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.GETALL_LANDLORD_CONTRACT_FAILURE, error } }
// }
// function getLocationList(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getLocationList(data)
//             .then(
//                 archives => dispatch(success(archives)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_REQUEST } }
//     function success(archives) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_SUCCESS, archives } }
//     function failure(error) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_LOCATION_FAILURE, error } }
// }

// function getJobList(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getJobList(data)
//             .then(
//                 jobs => dispatch(success(jobs)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.ARCHIVE_GETALL_REQUEST } }
//     function success(jobs) { return { type: archiveConstants.ARCHIVE_GETALL_SUCCESS, jobs } }
//     function failure(error) { return { type: archiveConstants.ARCHIVE_GETALL_FAILURE, error } }
// }

// function getDesignationList(data) {
//     return dispatch => {
//         dispatch(request());
//         archiveService.getDesignationList(data)
//             .then(
//                 designations => dispatch(success(designations)),
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST } }
//     function success(designations) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS, designations } }
//     function failure(error) { return { type: archiveConstants.GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE, error } }
// }
