import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";

import Dashboard from "../../pages/dashboard";
import UserManagement from "../../pages/UserManagement";
// import PlanManagement from "../../pages/PlanManagement";
import WorkerManagement from "../../pages/WorkerManagement";
import JobManagement from "../../pages/JobManagement";
import LandlordManagement from "../../pages/LandlordManagement";
import ContractorManagement from "../../pages/ContractorManagement";
import InternalStaffManagement from "../../pages/InternalStaffManagement";
import InternalStafflandlordManagement from "../../pages/InternalStaffandlordManagement/InternalStafflandlordManagement";
import JobTypeManagement from "../../pages/JobTypeManagement/JobTypeManagement";
import JobLandlordManagement from "../../pages/JobLandlordManagement/JobLandlordManagement";
import JobStatus from "../../pages/JobStatus/JobStatus";
import ArchiveManagement from "../../pages/ArchiveManagement/ArchiveManagement";
import DesignationManagement from "../../pages/DesignationManagement/DesignationManagement";

import { useLayoutState } from "../../context/LayoutContext";
import { store } from "../../_helpers";
import Loader from "./../Loader/Loader";
import LocationLandlordManagement from "../../pages/LocationLandlordManagement/LocationLandlordManagement";
import ContratorLandlordManagement from "../../pages/ContratorLandlordManagement/ContratorLandlordManagement";
import PackageManagement from "../../pages/PackageManagement";

function Layout(props) {
  let { } = store.getState();
  const { isLoading } = props;

  var classes = useStyles();
  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
      <>
        {isLoading && <Loader />}

        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />

          <Switch>
            <Route path="/app/dashboard" component={withRouter(Dashboard)} />
            <Route
              path="/app/users"
              exact
              component={withRouter(UserManagement)}
            />
            <Route
              path="/app/worker"
              exact
              component={withRouter(WorkerManagement)}
            />
            <Route
              path="/app/job"
              exact
              component={withRouter(JobManagement)}
            />
            <Route
              path="/app/landlord"
              exact
              component={withRouter(LandlordManagement)}
            />
            <Route
              path="/app/contractor"
              exact
              component={withRouter(ContractorManagement)}
            />
            <Route
              path="/app/internalStaff"
              exact
              component={withRouter(InternalStaffManagement)}
            />
            <Route
              path="/app/internalStaffLandlord"
              exact
              component={withRouter(InternalStafflandlordManagement)}
            />
            <Route
              path="/app/jobType"
              exact
              component={withRouter(JobTypeManagement)}
            />
            <Route
              path="/app/jobContractor"
              exact
              component={withRouter(JobLandlordManagement)}
            />
            <Route
              path="/app/jobStatus"
              exact
              component={withRouter(JobStatus)}
            />
            <Route
              path="/app/locationContractor"
              exact
              component={withRouter(LocationLandlordManagement)}
            />
            <Route
              path="/app/contractorLandlord"
              exact
              component={withRouter(ContratorLandlordManagement)}
            />
            <Route
              path="/app/archives"
              exact
              component={withRouter(ArchiveManagement)}
            />
            <Route
              path="/app/designation"
              exact
              component={withRouter(DesignationManagement)}
            />
            <Route
              path="/app/packagemanagement"
              exact
              component={withRouter(PackageManagement)}
            />
          </Switch>
        </div>
      </>
    </div>
  );
}

function mapStateToProps(state) {
  const { loader } = state;
  return {
    isLoading: loader.isLoading,
  };
}

export default connect(mapStateToProps)(withRouter(Layout));
