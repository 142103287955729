export const locationLandlordConstants = {
  LOGIN_REQUEST: "LOCATIONLANDLORDS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOCATIONLANDLORDS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOCATIONLANDLORDS_LOGIN_FAILURE",

  LOGOUT: "LOCATIONLANDLORDS_LOGOUT",

  GETALL_REQUEST: "GETALL_REQUEST",
  GETALL_SUCCESS: "GETALL_SUCCESS",
  GETALL_FAILURE: "GETALL_FAILURE",

  GET_ALL_LOCATIONS_LANDLORD_REQUEST: "GET_ALL_LOCATIONS_LANDLORD_REQUEST",
  GET_ALL_LOCATIONS_LANDLORD_SUCCESS: "GET_ALL_LOCATIONS_LANDLORD_SUCCESS",
  GET_ALL_LOCATIONS_LANDLORD_FAILURE: "GET_ALL_LOCATIONS_LANDLORD_FAILURE",

  ADD_LOCATION_LANDLORD_REQUEST: "ADD_LOCATION_LANDLORD_REQUEST",
  ADD_LOCATION_LANDLORD_SUCCESS: "ADD_LOCATION_LANDLORD_SUCCESS",
  ADD_LOCATION_LANDLORD_FAILURE: "ADD_LOCATION_LANDLORD_FAILURE",

  UPDATE_LOCATION_LANDLORD_REQUEST: "UPDATE_LOCATION_LANDLORD_REQUEST",
  UPDATE_LOCATION_LANDLORD_SUCCESS: "UPDATE_LOCATION_LANDLORD_SUCCESS",
  UPDATE_LOCATION_LANDLORD_FAILURE: "UPDATE_LOCATION_LANDLORD_FAILURE",
};
