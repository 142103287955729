
import React, { useMemo } from 'react';


function countTimeStampReduce(array) {
    if (!Array.isArray(array)) {
        return 0;
    }

    return array.reduce((acc, x) => acc + (x.offSiteTime - x.onSiteTime), 0);
}

function convertMilliseconds(milliseconds) {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    const remainingMilliseconds = milliseconds % 1000;

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        milliseconds: remainingMilliseconds
    };
}

const Invoice = ({ jobMaterialDetails, viewRowData }) => {
    const newData3 = useMemo(() => viewRowData?.assigned_worker_status?.reduce((acc, x) => acc + countTimeStampReduce(x.timeData), 0), [viewRowData])

    const materialDetails = []
    jobMaterialDetails.MaterialUsed.forEach(element => {
        return materialDetails.push(...element.materials)
    });
    const equipmentDetails = []
    jobMaterialDetails.MaterialUsed.forEach(element => {
        return equipmentDetails.push(...element.equipment)
    });

    const formatTime = (hours, minutes, seconds) => {
        return `${hours}h ${minutes}m ${seconds}s`;
    };


    return (
        <div style={{ padding: "0 20px", width: '80%', border: "5px solid #2e74b5", margin: "0 auto" }}>
            <div style={{ margin: 'auto', }}>
                <div style={{ display: "flex", justifyContent: "space-between", margin: '20px 0' }}>
                    <img
                        src="./Logo V2.jpg"
                        alt="logo"
                        width={80}
                    />
                    <h1 style={{ paddingRight: "70px" }}>INVOICE</h1>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', textTransform: "capitalize" }}>
                    <div style={{ width: '45%' }}>
                        <p><strong>Company Name: </strong>{viewRowData.assign_to.company_name}</p>
                        <p><strong>Job Address: </strong>{`${viewRowData.location_id.linecomplete}, ${viewRowData.location_id.pin}, ${viewRowData.location_id.city}, ${viewRowData.location_id.state}`}</p>
                        <p>Tax Registration No.: {jobMaterialDetails?.invoiceDetailsByJobId?.taxRegistrationNo || "N/A"}</p>
                        <p ><strong>Job Title:</strong> {viewRowData.job_title}</p>
                    </div>
                    <div style={{ width: '50%' }}>
                        <p><strong>Invoice : </strong>#{jobMaterialDetails?.invoiceDetailsByJobId?.invoiceNum || "N/A"}</p>
                        <p><strong>Invoice date:</strong> {jobMaterialDetails?.invoiceDetailsByJobId?.invoiceDate}</p>
                        <p ><strong>ATS Job ID No.: </strong> {viewRowData.job_id}</p>
                        <p><strong>Bill to: </strong>{`${viewRowData.created_by.company_name}`}</p>
                        <p><strong>Address:</strong> {`${viewRowData.created_by.address}`}</p>
                        <p><strong>Phone: </strong>{viewRowData.created_by.contact}</p>
                    </div>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Job Details</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty (hours)</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Rate</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td style={{ border: '1px solid #000', padding: '8px' }}>{viewRowData?.job_details}</td>
                                <td style={{ border: '1px solid #000', padding: '8px' }}>{formatTime(convertMilliseconds(newData3).hours, convertMilliseconds(newData3).minutes, convertMilliseconds(newData3).seconds)}</td>
                                <td style={{ border: '1px solid #000', padding: '8px' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.jobRate || 0 }</td>
                                <td style={{ border: '1px solid #000', padding: '8px' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.jobPrice || 0 }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Materials Description</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Per Item</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materialDetails.length > 0 && materialDetails.map((material, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{material.materialDescription}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{material.qty}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{material?.rate || 0}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{material?.price || 0}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Equipment Description</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Qty</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Per Item</th>
                                <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', backgroundColor: "#2e74b5", color: 'white' }}>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {equipmentDetails.length > 0 && equipmentDetails.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{item.equipmentDescription}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{item.qty}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{item?.rate || 0}</td>
                                    <td style={{ border: '1px solid #000', padding: '8px' }}>{item?.price || 0}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div style={{ marginBottom: '20px', display: 'flex', justifyContent: "flex-end" }}>
                    <table style={{ width: '50%', borderCollapse: 'collapse', border: '1px solid #000' }}>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Invoice Subtotal:</th>
                            <td style={{ padding: '8px', border: '1px solid #000' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.invoiceSubtotal}</td>
                        </tr>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Tax Rate:</th>
                            <td style={{ padding: '8px', border: '1px solid #000' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.taxRate}</td>
                        </tr>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Sales Tax:</th>
                            <td style={{ padding: '8px', border: '1px solid #000' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.salesTax}</td>
                        </tr>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Deposit Received:</th>
                            <td style={{ padding: '8px', border: '1px solid #000' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.depositReceived}</td>
                        </tr>
                        <tr>
                            <th style={{ padding: '8px', textAlign: 'left', border: '1px solid #000' }}>Total :</th>
                            <td style={{ padding: '8px', border: '1px solid #000' }}>{jobMaterialDetails?.invoiceDetailsByJobId?.invoiceSubtotal + jobMaterialDetails?.invoiceDetailsByJobId?.salesTax - jobMaterialDetails?.invoiceDetailsByJobId?.depositReceived}</td>
                        </tr>
                    </table>
                </div>


            </div>
        </div >
    );
};

export default Invoice;
