import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const contractorLandlordService = {
  getAllContractor,
  getContractorByLandord,
};

function logout() {
  localStorage.removeItem("contractor");
}

function getAllContractor(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL2 + `/getuserlist`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        getAllContractor: data.data,
      };
      return contractorObj;
    });
}

function getContractorByLandord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  console.log("@@@@@@@@@@@@@@@@@10101:", header.Authorization);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  // console.log("@@@@@@@@@@@@@@@@@", requestOptions);
  return fetch(CONST.BACKEND_URL2 + `/getContractorByLandord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        getContractorByLoginLandlord: data.data,
      };
      console.log("I am in service getContractorByLandLord:::", contractorObj);

      return contractorObj;
    });
}

function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
