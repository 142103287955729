import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,

  Typography,
} from "@material-ui/core";

import Select2 from 'react-select';


// import ListItemText from '@mui/material/ListItemText';

import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function AddWorkerDialog(props) {

  let {
    classes,
    workerAddCloseModal,
    selectedAssignedData,
    workerItems,
    assignWorkerForJobSubmit,
    handleAssignWorkerDropDown,
    addWorkerModal,
  } = props;

  // const [personName, setPersonName] = React.useState<string[]>([]);


  // console.log("101010101.........................__________", workerItems);
  console.log("MODAL___assignLandlordModal", assignWorkerForJobSubmit);
  console.log("MODAL___updateformData", handleAssignWorkerDropDown);

  let colourOptions = []
  const dataOptions = workerItems && workerItems.length>0 && workerItems.filter (ele=> ele.active === true)
  
  if (dataOptions && dataOptions.length > 0) {
    dataOptions.forEach((emp) => {
      let result = selectedAssignedData && selectedAssignedData.assigned_worker ? selectedAssignedData.assigned_worker.filter(o => (String(o.id )=== String(emp.id))) : [];
    
      if (result && result.length > 0) {
        colourOptions.push(
          {
            value: emp.first_name + " " + emp.last_name,
            label: emp.first_name + " " + emp.last_name,
            id: emp.id,
            disabled: true
          }
        )
      } else {
        colourOptions.push(
          {
            value: emp.first_name + " " + emp.last_name,
            label: emp.first_name + " " + emp.last_name,
            id: emp.id,
            disabled: false
          }
        )
      }


    })
  }
  
  let freezOptions = []
  if (workerItems && workerItems.length > 0) {
    workerItems.forEach((emp) => {
      freezOptions.push(
        {
          value: emp.first_name + " " + emp.last_name,
          label: emp.first_name + " " + emp.last_name,
          id: emp.id,
        }
      )
      const autoselect = workerItems.slice(0, 2);
      console.log("autoselectautoselectautoselect", autoselect);
    })
  }
  console.log("MODAL______colourOptions::", freezOptions);




  return (
    <Dialog fullScreen open={addWorkerModal} onClose={workerAddCloseModal}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={workerAddCloseModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add Worker
          </Typography>
          <Button color="inherit" onClick={workerAddCloseModal}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Worker
          </Typography>

          {/* {JSON.stringify(selectedAssignedData.assigned_worker)} */}
          <ValidatorForm onSubmit={assignWorkerForJobSubmit}>
            <Grid container spacing={2} style={{ width: '500px' }}>

              <Grid item xs={12} sm={12} style={{ margin: "0px", width: '100%' }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Worker List</label>

                <Select2
                  id="workerList"
                  isMulti
                  width="full"
                  name="workerList"
                  defaultValue={selectedAssignedData && selectedAssignedData.assigned_worker ?
                    selectedAssignedData.assigned_worker.map((element) => ({
                      value: element.first_name + " " + element.last_name,
                      label: element.first_name + " " + element.last_name,
                      id: element.id,
                    })) : []}

                  onChange={handleAssignWorkerDropDown}
                  options={colourOptions}
                  isOptionDisabled={(option) => option.disabled}
                  className="basic-multi-select width: 'full'"
                  classNamePrefix="Activities"
       
                />

              </Grid>



              <div style={{ margin: "7px" }}></div>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>

        </div>
      </Container>
    </Dialog>
  );
}
