import React from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  CssBaseline,
  Grid,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function UpdateLandlordDialog(props) {
  let {
    classes,
    updateformData,
    updateuserCreateModal,
    updatehandleClose,
    updatehandleChangeInput,
    getLandlordListItems,
    updatehandleSubmit,
  } = props;

  return (
    <Dialog fullScreen open={updateuserCreateModal} onClose={updatehandleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={updatehandleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update Contractor
          </Typography>
          <Button color="inherit" onClick={updatehandleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Contractor
          </Typography>

          <ValidatorForm onSubmit={updatehandleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Company Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="company_name"
                  id="company_name"
                  value={updateformData.company_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Contact Name*"
                  id="contact_name"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="contact_name"
                  value={updateformData.contact_name}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginTop: "10px", cursor:"pointer"}}>
                <Select
                  name="Landlord"
                  style={{ width: '100%' }}
                  onChange={updatehandleChangeInput}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Landlord List
                  </MenuItem>

                  {/* <MenuItem value="text">text</MenuItem>
                  <MenuItem value="video">video</MenuItem> */}
                  {/* <option selected>Open this select menu</option> */}
                  {
                    getLandlordListItems && getLandlordListItems.length > 0 ?
                      getLandlordListItems.map((element, index) => (

                        <option value={element && element.id ? element.id : "NA"}>{element && element.company_name ? element.company_name : "NA"}</option>

                      ))
                      : null
                  }
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Email*"
                  id="email"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="email"
                  disabled={true}
                  value={updateformData.email}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Contact No.*"
                  id="contact"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="contact"
                  value={updateformData.contact}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextValidator
                  label="Address*"
                  id="address"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="address"
                  value={updateformData.address}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    </Dialog>
  );
}
