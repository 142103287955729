import { designationConstants } from "../_constants";
import { designationService } from "../_services";
import { alertActions, loaderActions } from "./";
import { history } from "../_helpers";
export const designationActions = {
  login,
  logout,
  addDesignation,
  uploadImage,
  disableDesignation,
  deleteDesignation,
  updateDesignation,
  getAllDesignation,
  getDesignationList,
};
function login(data) {
  return (dispatch) => {
    dispatch(request({ data }));
    designationService.login(data).then(
      (designation) => {
        dispatch(success(designation));
        history.push({ pathname: "#/app/dashboard" });
        window.location.reload();
      },
      (error) => {
        console.log("errorerror ", error);
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };

  function request(designation) {
    return { type: designationConstants.LOGIN_REQUEST, designation };
  }
  function success(designation) {
    return { type: designationConstants.LOGIN_SUCCESS, designation };
  }
  function failure(error) {
    return { type: designationConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  designationService.logout();
  return { type: designationConstants.LOGOUT };
}
function getDesignationList(data) {
  return (dispatch) => {
    dispatch(request());
    designationService.getDesignationList(data).then(
      (designations) => dispatch(success(designations)),
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.GETALL_REQUEST };
  }
  function success(designations) {
    return { type: designationConstants.GETALL_SUCCESS, designations };
  }
  function failure(error) {
    return { type: designationConstants.GETALL_FAILURE, error };
  }
}
function getAllDesignation(data) {
  return (dispatch) => {
    dispatch(request());
    designationService.getAllDesignation(data).then(
      (designations) => {
        //console.log("designations  ",designations);
        let returnResppnse = designations.getAllDesignation
          ? designations.getAllDesignation.map((designation) => ({
              value: designation.id,
              label: designation.firstName + " " + designation.lastName,
            }))
          : [];
        let newRespnse = { ...designations, getAllDesignation: returnResppnse };
        dispatch(success(newRespnse));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.GET_ALL_DESIGNATION_REQUEST };
  }
  function success(designations) {
    return {
      type: designationConstants.GET_ALL_DESIGNATION_SUCCESS,
      designations,
    };
  }
  function failure(error) {
    return { type: designationConstants.GET_ALL_DESIGNATION_FAILURE, error };
  }
}
function addDesignation(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    designationService.addDesignation(data).then(
      (designations) => {
        dispatch(success(designations));
        dispatch(this.getDesignationList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.ADD_RESTAURANT_DESIGNATION_REQUEST };
  }
  function success(designations) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_SUCCESS,
      designations,
    };
  }
  function failure(error) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_FAILURE,
      error,
    };
  }
}
function updateDesignation(data) {
  let tempdata = {
    // keyWord: "",
    // pageNo: 1,
    // size: 10,
  };
  return (dispatch) => {
    dispatch(request());
    designationService.updateDesignation(data).then(
      (designations) => {
        dispatch(success(designations));
        dispatch(this.getDesignationList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.ADD_RESTAURANT_DESIGNATION_REQUEST };
  }
  function success(designations) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_SUCCESS,
      designations,
    };
  }
  function failure(error) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_FAILURE,
      error,
    };
  }
}
function disableDesignation(data) {
  const { value } = data;
  // let tempdata = {
  //   keyWord: "",
  //   pageNo: 1,
  //   size: 10,
  // };
  return (dispatch) => {
    dispatch(loaderActions.startLoader());
    dispatch(request());
    designationService.disableDesignation(data).then(
      (designations) => {
        dispatch(
          alertActions.success(
            `User ${value ? "enabled" : "disabled"} successfully`,
          ),
        );
        // dispatch(success(designations));
        // dispatch(this.getDesignationList(tempdata));
        dispatch(loaderActions.stopLoader());
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.ADD_RESTAURANT_DESIGNATION_REQUEST };
  }
  // function success(designations) {
  //   return {
  //     type: designationConstants.ADD_RESTAURANT_DESIGNATION_SUCCESS,
  //     designations,
  //   };
  // }
  function failure(error) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_FAILURE,
      error,
    };
  }
}
function deleteDesignation(data) {
  let tempdata = {
    keyWord: "",
    pageNo: 1,
    size: 10,
  };
  let maintempdata = {
    id: data.id,
  };
  return (dispatch) => {
    dispatch(request());
    designationService.deleteDesignation(maintempdata).then(
      (designations) => {
        dispatch(success(designations));
        dispatch(this.getDesignationList(tempdata));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function request() {
    return { type: designationConstants.ADD_RESTAURANT_DESIGNATION_REQUEST };
  }
  function success(designations) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_SUCCESS,
      designations,
    };
  }
  function failure(error) {
    return {
      type: designationConstants.ADD_RESTAURANT_DESIGNATION_FAILURE,
      error,
    };
  }
}
function uploadImage(data) {
  return (dispatch) => {
    designationService.uploadImage(data).then(
      (uploadImage) => {
        dispatch(success(uploadImage));
      },
      (error) => {
        dispatch(alertActions.error(error));
        dispatch(failure(error));
      },
    );
  };
  function success(uploadImage) {
    return {
      type: designationConstants.FILE_UPLOAD_STATUS_SUCCESS,
      uploadImage,
    };
  }
  function failure(error) {
    return { type: designationConstants.FILE_UPLOAD_STATUS_FAILURE, error };
  }
}
