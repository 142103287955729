export const contractorLandlordConstants = {
  LOGOUT: "CONTRACTORS_LOGOUT",

  GET_ALL_CONTRACTOR_REQUEST: "GET_ALL_CONTRACTOR_REQUEST",
  GET_ALL_CONTRACTOR_SUCCESS: "GET_ALL_CONTRACTOR_SUCCESS",
  GET_ALL_CONTRACTOR_FAILURE: "GET_ALL_CONTRACTOR_FAILURE",

  GET_CONTRACTOR_BY_LANDLORD_REQUEST: 'GET_CONTRACTOR_BY_LANDLORD_REQUEST',
  GET_CONTRACTOR_BY_LANDLORD_SUCCESS: 'GET_CONTRACTOR_BY_LANDLORD_SUCCESS',
  GET_CONTRACTOR_BY_LANDLORD_FAILURE: 'GET_CONTRACTOR_BY_LANDLORD_FAILURE',
};
