import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    background: "rgb(4 6 19 / 58%);",
    zIndex: "2222",
    overflowY: "none",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    position: "absolute",
    animationDuration: "550ms",
    top: "48vh",
    left: "50%",
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function Loader(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default Loader;
