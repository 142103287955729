import { authHeader } from "../_helpers";
import { CONST } from "../_config";

export const contractorService = {
  login,
  logout,
  addContractor,
  updatePassword,
  uploadImage,
  getContractorList,
  disableContractor,
  updateContractor,
  assignLandlord,
  deleteContractor,
  getAllContractor,
  getLandlordList
};
function logout() {
  localStorage.removeItem("contractor");
}
function login(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/adminlogin`, requestOptions)
    .then(handleResponse)
    .then((contractor) => {
      let contractorObj = {
        contractorinfo: contractor.data,
      };
      if (contractor.data) {
        localStorage.setItem("contractor", JSON.stringify(contractor.data));
      }

      return contractorObj;
    });
}

function updatePassword(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updatePassword`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let userObj = {
        updatePassword: data.data,
      };
      console.log("I am in service", userObj);

      return userObj;
    });
}

function getLandlordList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        getLandlordList: data.data,
      };
      console.log();

      return contractorObj;
    });
}

function getContractorList(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        getContractorList: data.data,
      };
      console.log();

      return contractorObj;
    });
}
function getAllContractor() {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
  };
  return fetch(CONST.BACKEND_URL + `/getAllUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        getAllContractor: data.data,
      };
      console.log();

      return contractorObj;
    });
}
function addContractor(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/createNewUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        addContractor: data.data,
      };
      console.log();

      return contractorObj;
    });
}
function assignLandlord(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/assignLandlord`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      console.log("service___________________called",data)
      let contractorObj = {
        addContractor: data.data,
      };
      return contractorObj;
    });
}
function updateContractor(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/updateUser`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        addContractor: data.data,
      };
      return contractorObj;
    });
}
function disableContractor(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/changeState`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        addContractor: data.data,
      };
      return contractorObj;
    });
}
function deleteContractor(data) {
  let header = new Headers({
    "Content-Type": "application/json",
    Authorization: authHeader().Authorization,
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  };
  return fetch(CONST.BACKEND_URL + `/deleteContractor`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      let contractorObj = {
        addContractor: data.data,
      };
      console.log();

      return contractorObj;
    });
}
function uploadImage(filedata) {
  let header = new Headers({
    Authorization: authHeader().Authorization,
  });
  var data = new FormData();
  data.append("image", filedata);

  const requestOptions = {
    method: "POST",
    headers: header,
    body: data,
  };
  return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      let contractorObj = {
        filesDetails: res.data,
      };
      return contractorObj;
    });
}
function handleResponse(response) {
  // console.log("response22222   ", response);

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload(true);
      }
      console.log("datadatadata ", response);

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    //console.log("datadatadatadatadata   ", data.error);
    if (data.error) {
      console.log("datadatadatadatadata   ", data);
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
