import { joblandlordConstants } from "../_constants";

export function joblandlords(state = {}, action) {
  switch (action.type) {
    case joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_SUCCESS:
      return {
        ...state,
        addJobTypeSuccess: false,
        designationListitems: action.designations.getDesignationList.list,
        designationListtotal: action.designations.getDesignationList.total,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_DESIGNATION_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_SUCCESS:
      return {
        ...state,
        addJobTypeSuccess: false,
        locationListitems: action.joblandlords.getLocationList.list.filter(
          (location) => location != null,
        ),
        locationListtotal: action.joblandlords.getLocationList.total,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GETALL_LANDLORD_JOBTYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GETALL_LANDLORD_JOBTYPE_SUCCESS:
      return {
        ...state,
        addJobTypeSuccess: false,
        jobTypeItems: action.joblandlords.getJobTypeList.list,
        jobTypeTotal: action.joblandlords.getJobTypeList.total,
      };
    case joblandlordConstants.GETALL_LANDLORD_JOBTYPE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GETALL_LANDLORD_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GETALL_LANDLORD_CONTRACT_SUCCESS:
      return {
        ...state,
        addJobTypeSuccess: false,
        contractItems: action.joblandlords.getContractTypeList.list,
        contractTotal: action.joblandlords.getContractTypeList.total,
      };
    case joblandlordConstants.GETALL_LANDLORD_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GETALL_SUCCESS:
      return {
        ...state,
        addJobLandlordSuccess: false,
        items: action.joblandlords.getFilteredAssignedJob.list,
        total: action.joblandlords.getFilteredAssignedJob.total,
      };
    case joblandlordConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case joblandlordConstants.GET_WORKER_BY_CONTRACTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GET_WORKER_BY_CONTRACTOR_SUCCESS:
      return {
        ...state,
        addJobLandlordSuccess: false,
        workerItems: action.joblandlords.getWorkerList.list,
        workerTotal: action.joblandlords.getWorkerList.total,
      };
    case joblandlordConstants.GET_WORKER_BY_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case joblandlordConstants.REMOVE_JOB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.REMOVE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        addJobLandlordSuccess: true,

      };
    case joblandlordConstants.REMOVE_JOB_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GET_ALL_JOBLANDLORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_SUCCESS:
      return {
        ...state,
        getAllJobLandlord: action.joblandlords.getAllJobLandlord,
      };
    case joblandlordConstants.GET_ALL_JOBLANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GETALL_JOBLANDLORD_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GETALL_JOBLANDLORD_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.joblandlords.listOfNotification.list,
        listOfNotificationtotal: action.joblandlords.listOfNotification.total,
      };
    case joblandlordConstants.GETALL_JOBLANDLORD_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.UPDATE_JOBLANDLORD_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.UPDATE_JOBLANDLORD_NOTIFY_SUCCESS:
      return {
        ...state,
      };
    case joblandlordConstants.UPDATE_JOBLANDLORD_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.GET_RESTAURANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.GET_RESTAURANT_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        updateCategory: false,
        restaurantDetails: action.joblandlords.restaurantDetails,
      };
    case joblandlordConstants.GET_RESTAURANT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_SUCCESS:
      return {
        ...state,
        addJobLandlordSuccess: true,
      };
    case joblandlordConstants.ADD_RESTAURANT_JOBLANDLORD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case joblandlordConstants.ASSIGN_JOB_TO_WORKER_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.ASSIGN_JOB_TO_WORKER_SUCCESS:
      return {
        ...state,
        addJobLandlordSuccess: true,
      };
    case joblandlordConstants.ASSIGN_JOB_TO_WORKER_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case joblandlordConstants.ADD_MENU_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true,
      };
    case joblandlordConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.ADD_ITEM_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true,
      };
    case joblandlordConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true,
      };
    case joblandlordConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state,
      };
    case joblandlordConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true,
      };
    case joblandlordConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case joblandlordConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        filesDetails: action.uploadImage.filesDetails,
      };

    case joblandlordConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
      };

    case joblandlordConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case joblandlordConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.joblandlords.statics,
      };
    case joblandlordConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
