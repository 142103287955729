export const contractorConstants = {
    LOGIN_REQUEST: 'CONTRACTORS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'CONTRACTORS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'CONTRACTORS_LOGIN_FAILURE',

    LOGOUT: 'CONTRACTORS_LOGOUT',

    GETALL_LANDLORD_LIST_REQUEST: 'GETALL_LANDLORD_LIST_REQUEST',
    GETALL_LANDLORD_LIST_SUCCESS: 'GETALL_LANDLORD_LIST_SUCCESS',
    GETALL_LANDLORD_LIST_FAILURE: 'GETALL_LANDLORD_LIST_FAILURE',

    ASSIGN_LANDLORD_REQUEST: 'ASSIGN_LANDLORD_REQUEST',
    ASSIGN_LANDLORD_SUCCESS: 'ASSIGN_LANDLORD_SUCCESS',
    ASSIGN_LANDLORD_FAILURE: 'ASSIGN_LANDLORD_FAILURE',

    GETALL_REQUEST: 'CONTRACTORS_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONTRACTORS_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONTRACTORS_GETALL_FAILURE',

    GET_ALL_CONTRACTOR_REQUEST: 'CONTRACTORS_GET_ALL_CONTRACTOR_REQUEST',
    GET_ALL_CONTRACTOR_SUCCESS: 'CONTRACTORS_GET_ALL_CONTRACTOR_SUCCESS',
    GET_ALL_CONTRACTOR_FAILURE: 'CONTRACTORS_GET_ALL_CONTRACTOR_FAILURE',
    
    GETALL_CONTRACTOR_NOTIFY_REQUEST: 'GETALL_CONTRACTOR_NOTIFY_REQUEST',
    GETALL_CONTRACTOR_NOTIFY_SUCCESS: 'GETALL_CONTRACTOR_NOTIFY_SUCCESS',
    GETALL_CONTRACTOR_NOTIFY_FAILURE: 'GETALL_CONTRACTOR_NOTIFY_FAILURE',

    UPDATE_CONTRACTOR_NOTIFY_REQUEST: 'UPDATE_CONTRACTOR_NOTIFY_REQUEST',
    UPDATE_CONTRACTOR_NOTIFY_SUCCESS: 'UPDATE_CONTRACTOR_NOTIFY_SUCCESS',
    UPDATE_CONTRACTOR_NOTIFY_FAILURE: 'UPDATE_CONTRACTOR_NOTIFY_FAILURE',


    ADD_RESTAURANT_CONTRACTOR_REQUEST: 'ADD_RESTAURANT_CONTRACTOR_REQUEST',
    ADD_RESTAURANT_CONTRACTOR_SUCCESS: 'ADD_RESTAURANT_CONTRACTOR_SUCCESS',
    ADD_RESTAURANT_CONTRACTOR_FAILURE: 'ADD_RESTAURANT_CONTRACTOR_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    GETALL_CONTRACTOR_LIST_REQUEST: 'GETALL_CONTRACTOR_LIST_REQUEST',
    GETALL_CONTRACTOR_LIST_SUCCESS: 'GETALL_CONTRACTOR_LIST_SUCCESS',
    GETALL_CONTRACTOR_LIST_FAILURE: 'GETALL_CONTRACTOR_LIST_FAILURE',
    

    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CONTRACTOR_PASSWORD_REQUEST: 'UPDATE_CONTRACTOR_PASSWORD_REQUEST',
    UPDATE_CONTRACTOR_PASSWORD_SUCCESS: 'UPDATE_CONTRACTOR_PASSWORD_SUCCESS',
    UPDATE_CONTRACTOR_PASSWORD_FAILURE: 'UPDATE_CONTRACTOR_PASSWORD_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
