import React, {  } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  // Container,
  // CssBaseline,
  // Grid,
  Typography,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function ProfileLandlord(props) {
  let {
    classes,
    // formData,
    jobCreateModal,

    handleClose,
    // handleViewClose,
    viewRowData,
    // handleChangeInput,
    // handleSubmit,
    // data
    // pdfDetailsModal,
    // handleClosePdf,
    // handleOpenModal,
    landlord,
  } = props;
  // console.log("ViewLandlord det", landlord);
  // const classes = "";

  return (
    <>
      <div>
        <div
          style={{
            padding: "0 30px",
          }}
        >
          <div className={classes.paper1}>
            <Typography component="h1" variant="h5" className={classes.title1}>
              <u>Personal Details</u>
            </Typography>
            <div className={classes.paper1}>
              {landlord ? (
                <Typography
                  style={{ fontSize: "17px" }}
                  component="h1"
                  variant="h5"
                >
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Name :</b>
                    {landlord.contact_name}
                  </Box>
                  <Box
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      margin: "5px 0",
                    }}
                  >
                    <b>Company Name :</b>
                    {landlord.company_name}
                  </Box>
                  <Box style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    <b>Address :</b>
                    {landlord.address}
                  </Box>

                  <Box
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      margin: "5px 0",
                    }}
                  >
                    <b>Email :</b>
                    {landlord.email}
                  </Box>
                  <Box className="pl-3 fw-normal">
                    <b>Contact No:</b>
                    {landlord.contact}
                  </Box>

                  <br />
                  <br />
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
